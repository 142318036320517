// Badge

.badge {
  color: $white;
  font-weight: 400;
  &[class*="badge-"] {
    [class*="icon-"] {
      line-height: 1;
    }

    a {
      color: $white;
    }

    span {
      vertical-align: middle;
    }

    svg {
      & ~ span {
        margin-left: 0.5rem;
      }
    }
  }

  // square badge
  &.badge-square {
    border-radius: 0;
  }

  // badge-up
  // to align badge over any element
  &.badge-up {
    position: absolute;
    top: -1rem;
    right: -1rem;
    &.badge-sm {
      top: -0.5rem;
      right: -0.5rem;
    }
  }

  &.light-dark {
    color: #7d8c91;
  }

  &.dark-gray {
    height: 24px !important;

    background-color: #8c919f;
    color: $white;
    padding: 6px 8px !important;
    border-radius: 66px !important;
    font-size: 10px !important;
    line-height: 13px !important;
  }
}

// badge sizes
.badge-xl {
  font-size: 1.8rem;
}
.badge-lg {
  font-size: 1.2rem;
}
.badge-md {
  font-size: 1rem;
}
.badge-sm {
  font-size: 0.7rem;
}

// For fullscreen search
.badge-icon {
  svg {
    font-size: 100%;
    margin-right: 5px;
  }
}

// badge dropup pointer
.dropup {
  .badge {
    cursor: pointer;
  }
}
