.fts-description-length {
  margin-left: 32px;
  .fts-description-length-title {

    font-size: 14px;
    line-height: 18.59px;
    letter-spacing: 0.04rem;
    margin-bottom: 1rem;
    color: #4b5457 !important;
  }
  .fts-description-length-item {
    display: flex;
    flex-direction: column;

    .type {

      margin-bottom: 6px;
      color: #7d8c91;
      font-size: 14px;
      line-height: 17px;
    }

    .length {

      margin-bottom: 24px;
      color: #32383a;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
