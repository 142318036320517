.fts-change-listing-type-sidebar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: calc(100vh - 82px);
  background-color: #f4f6f8;
  width: 100%;

  .fts-change-listing-type-sidebar-content {
    padding: 32px;

    .listing-details-card {
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 3px 9px 0px rgba(237, 237, 243, 1);
      padding: 32px;

      .listing-details-title {
        margin-left: -32px;
        margin-bottom: 1rem;
      }

      .fts-primary-devider {
        margin-bottom: 1rem;
      }

      .listing-info-row {
        margin-bottom: 1rem;
      }

      .listing-field-preview {
        margin-bottom: 3rem;
        .listing-field-preview-label {

          color: #7d8c91;
          font-size: 12px;
          line-height: 14px;
        }

        .listing-field-preview-value {

          color: #32383a;
          font-size: 14px;
          line-height: 16px;
          margin-top: 6px;
        }
      }

      .listing-details-form {
        margin-top: 1rem;
      }
    }
  }

  .fts-change-listing-type-sidebar-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 113px;
    padding: 32px;
    background-color: #fff;
    border-top: 1px solid #e3e7eb;

    .back-button {
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
        margin-left: -1rem;
        path {
          stroke: #626262 !important;
        }
      }
    }
  }
}

.fts-send-changed-listings-proof-context {
  position: absolute;
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e5e8e9;
  min-width: 200px;
  border-radius: 4px;
  bottom: 100px;
  right: 205px;
  width: 270px;

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #f1f5fa;
    height: 48px;
    border-radius: 4px;
    margin-bottom: 24px;
  }

  .tab {
    height: 40px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    flex: 1;
    font-weight: normal !important;

    font-size: 14px;
    line-height: 16px;
    margin-left: 4px;
    margin-right: 4px;
    outline: none;
  }

  .tab:focus {
    outline: 0 !important;
  }

  .selected {
    border: none;
    background-color: #ffffff;
    color: #242d34;
    box-shadow: 0px 2px 5px #cdd8e6;
  }

  .unselected {
    background-color: transparent;
    border: none;
    color: #7d8c91;
  }
}
