.task-management {
  &__container {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 4px;
    height: 74vh;
    overflow: auto;
  }

  &__column {
    border: 1px solid #ededed;
    display: flex;
    flex-direction: column;
  }
}

.column-item-dragging {
  border: 1px solid #01b5f5 !important;
}

.task-management-wrapper {
  min-height: auto;
}

.column-items {
  margin-top: 24px;
  min-height: 210px;
  width: 100%;
  padding-bottom: 2px;
}

.column-items-dragging-over {
  background-color: #e5eaf1;
  transition: background-color 0.2s ease;
  border-radius: 4px;
  width: 299px;
}

.tasks-column {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 316px;
  margin-right: 2rem;

  .scrollbar-container {
    .ps__rail-y {
      display: none !important;
    }
  }

  &:first-child {
    margin-left: 1rem;
  }

  &__wrapper {
    height: 100%;
    overflow: auto;
  }

  &__header {
    width: 299px;
    display: flex;
    align-items: center;
    height: 56px;
    padding: 24px 8px 24px 0;
    // background-color: #f0f0f0;
    position: relative;

    .top-bar {
      position: absolute;
      bottom: 0%;
      left: 0;
      right: 0;
      height: 2px;
      // background-color: #ededed;
    }
  }

  &__null {
    display: flex;
    font-size: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  &__title {
    flex: 1;
    font-size: 15px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #242d34;

      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      span {
        &:last-of-type {
          color: #fff;
          font-size: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 0;
          font-weight: 700;
          width: 32px;
          height: 32px;
          flex: 0 0 32px;
          background-color: #ededed;
          border-radius: 50%;
          border: 1px solid;
          font-size: 16px;
          line-height: 16px;
          font-weight: 500 !important;
        }
      }
    }
  }

  &__cta {
    display: flex;
    align-items: center;
  }

  &__new-item {
    .new-cta {
      appearance: none;
      padding: 0;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
}

.employee-picker-gray-box {
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1rem;
}
