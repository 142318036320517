@import "../../core/variables/variables";

.fts-todo-wrapper {
  padding: 0;

  .fts-todo {
    &__participants {
      display: flex;
      flex-direction: row;
      .avatar-owner {
        margin-right: 1.1rem !important;
        background-color: #fbfcfd !important;
        color: #242d34 !important;
        border: 1px solid #e3e7eb !important;
      }
    }
    &__people {
      width: 70%;
      display: flex;
      flex-direction: row;
      margin-top: 1rem;
    }

    &__owner {
      color: #7d8a95;
      .owner {
        position: relative;
        .owner-crown {
          position: absolute;
          z-index: 1;
          left: 15px;
          top: -10px;
          svg {
            stroke: none;
            width: 16px;
          }
        }
      }
      > div {
        &:last-of-type {
          margin-left: 5px;
          display: flex;
          flex-direction: row;

          font-size: 13px;
          line-height: 16px;
          font-weight: normal;
        }
      }

      h4 {
        margin: 0;
        font-size: 11px;
        color: #242d34;
        font-weight: normal;

        + div {
          font-size: 9px;
          color: #7d8c91;
          line-height: 1;
        }
      }
    }

    &__participants {
      display: flex;
      flex-direction: row;

      .avatar {
        margin: 0;
        border: 1px solid #fff;
        color: #242d34;
        transition: all 0.3s ease;
        background-color: #f0f0f0;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        margin-right: -12px;
      }

      &:hover {
        .avatar {
          margin-right: 3px;
        }
      }
    }
  }

  .fts-account-listing-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
    color: #242d34;
  }

  .archived {
    background-color: #f1f5fa !important;
    border: 1px solid #f1f5fa !important;
    border-radius: 4px;
    .avatar {
      color: #7d8c91;
      border-color: #f8f8f8;
    }
    hr {
      border-color: #e9e9e9;
    }
    .fts-personal {
      color: #242d34 !important;
    }
    .fts-listing {
      color: #424c54 !important;
    }
    .fts-todo-title {
      color: #242d34 !important;
    }
  }

  .archived:hover {
    border: 1px solid #01b5f5;
  }

  .fts-todo-title-no-pointer {
    cursor: default;

    font-size: 13px;
    line-height: 16px;
    color: #242d34;
    font-weight: normal;
  }

  .red-component {
    border-radius: 4px;
    background-color: #fbeef1;
    border: 1px #ce1a3b solid !important;
    .fts-head .fts-listing-account {
      background-color: #ffa8a8 !important;
      //word-break: break-all;
    }
    .fts-todo__owner {
      color: #242d34 !important;
    }
    .avatar {
      background-color: #e4c6c6;
      border-color: #ffecec;
    }
    hr {
      border-color: #e3e7eb !important;
    }
    .fts-listing {
      color: #9a6c6c !important;
    }
    .fts-todo-title {
      color: #242d34 !important;
    }
  }

  .overdue-component {
    background-color: #ffe1e6 !important;
  }

  .red-border {
    border: 1px solid #ce1b3b !important;
  }

  .red-component:hover {
    border: 1px solid #01b5f5 !important;
  }

  .fts-todo-shadow {
    box-shadow: 0px 2px 5px #e3e7f0;
  }

  .fts-todo-small {
    display: flex;
    border-radius: 4px;
    align-items: center;
    align-content: center;
    background-color: #fff;
    padding: 0;
    transition: all 0.3s ease;
    width: 298px;
    // box-shadow: 0px 2px 5px #e8e8e8;
    // border: 1px solid #ce1a3b;
    // margin: 0 12px 16px 12px;
    margin-bottom: 1rem;

    .fts-todo-head-red {
      padding-bottom: 0.5rem;
      hr {
        border-color: #e4c6c6;
      }
    }

    .fts-todo-small-body:hover {
      border: 1px solid #01b5f5 !important;
    }

    .dropdown-button {
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      background-color: transparent;
      border: transparent;
      svg {
        height: 15px;
        stroke: #9ca6a9;
        fill: #9ca6a9;
        stroke-width: 2;
      }
    }

    .fts-personal-card {
      background-color: #f1fbff;
      border: 1px solid #f1fbff !important;
      border-radius: 4px;
      .avatar {
        border-color: #f1fbff !important;
      }
    }

    .fts-personal-card-red {
      background-color: #f1fbff;
      border: 1px solid #ce1b3b !important;
      border-radius: 4px;
      .avatar {
        border-color: #f1fbff !important;
      }
    }

    .fts-personal-card:hover {
      border: 1px solid #01b5f5 !important;
    }

    .fts-personal {
      color: #242d34;
      display: inline-block;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      font-size: 15px;
    }

    .fts-listing-account {
      display: flex;
      flex-direction: column;
      height: auto;
      border-radius: 1px;
      //word-break: break-all;

      .listings-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        .main-listing {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .listing-button {
            background: #fbfcfd;
            border: 1px solid #e3e7eb;
            margin-left: 12px;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              transition: all 0.4s ease;
            }
          }

          .listing-button:hover {
            cursor: pointer;
          }

          .collapsed {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .collapse-listings {
          transition: all 1s ease;
          display: flex;
          flex-direction: column;

          .fts-listing {
            display: inline-block;
            margin-top: 8px;
          }
        }
      }

      .fts-listing {
        font-size: 13px;
        line-height: 16px;
        color: #424c54;

        margin-top: 6px;
      }
    }

    .fts-account:hover {
      text-decoration: underline;
      color: #01b5f5 !important;
      cursor: pointer;
    }

    .fts-listing-link:hover {
      text-decoration: underline;
      color: #01b5f5 !important;
      cursor: pointer;
    }

    .fts-listing-account:hover .fts-listing:hover {
      cursor: pointer;
    }

    .hr-red {
      border-color: #e4c6c6;
      margin: 11px 0 11px 0;
    }

    .hr {
      margin: 11px 0 11px 0;
    }

    .fts-todo-status-label {
      background-color: $success;
      width: 4px;
      height: 100%;
      writing-mode: vertical-rl;
      text-align: left;
      text-orientation: upright;
      text-transform: uppercase;
      transition: all 0.3s ease;

      div {
        width: 0;
      }
    }

    .fts-todo-small-body {
      flex: 1;
      padding: 24px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid #fff;

      .fts-badge {
        font-weight: bold;
      }

      &.active {
        border-color: $success;
      }

      &.inactive {
        border-color: $gray-300;
      }

      &.expired {
        border-color: $danger;
      }

      .fts-todo-title {
        color: #242d34;
        font-size: 13px;
        line-height: 16px;
        // word-break: break-word;
      }

      .fts-todo-row {
        margin-top: 16px;
        color: #7d8c91;
      }

      .fts-todo-top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #7d8c91;
        line-height: 16px;
        font-size: 13px;

        .header-right {
          display: flex;

          .colapse-button {
            display: flex;
            align-items: center;
            background-color: transparent;
            border: none;
            margin: 0;
            padding: 0;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #7d8a95;
            margin-left: 1rem;
          }
        }

        .fts-icon {
          margin-right: 0.25rem;
        }
        .red {
          color: #ce1b3b !important;
          stroke: #ce1b3b !important;
        }

        .fts-todo-meta {
          svg {
            fill: #f36565 !important;
          }
        }

        .archived-row {
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 16px;

          margin-bottom: -2px;
          color: #7d8c91;
          text-transform: capitalize;
          svg {
            stroke-width: 1;
            width: 15px;
            margin-right: 0.5rem;
          }
        }
        .fts-todo-date {
          display: flex;
          align-items: center;
          font-size: 13px;
          line-height: 16px;

          margin-bottom: -2px;
          color: #7d8c91;
          text-transform: capitalize;
          svg {
            stroke-width: 1.5;
            width: 15px;
            margin-right: 6px;
            padding-bottom: 2px;
          }

          small {
            vertical-align: middle;
            margin-left: 0.2rem;
          }
        }
      }

      .fts-todo-bottom-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #7d8c91;
        line-height: 16px;
        font-size: 13px;

        .fts-icon {
          margin-right: 0.25rem;
        }
        .red {
          color: #f36565 !important;
          stroke: #f36565 !important;
        }

        .fts-todo-meta {
          svg {
            fill: #f36565 !important;
          }
        }

        .fts-todo-date {
          display: flex;
          align-items: center;
          font-size: 16px;

          margin-bottom: -2px;
          color: #7d8c91;
          text-transform: capitalize;
          svg {
            stroke-width: 1;
          }

          small {
            vertical-align: middle;
            margin-left: 0.2rem;
          }
        }

        .fts-todo-attached {
          span {
            padding-left: 0.25rem;
            font-size: 13px;
            line-height: 16px;
            color: #7d8c91;
          }
          svg {
            stroke-width: 1;
          }
        }
      }
    }

    &:hover {
      box-shadow: 0px 3px 16px #e8ebf1;
      transition: all 0.3s ease;
      // transform: translateY(-5px);
      z-index: 0;

      .fts-todo-status-label {
        padding-left: 1.5rem;
        transition: all 0.3s ease;
      }
    }

    .fts-todo-small-header {
      margin-bottom: 0.5rem;
    }

    hr {
      background-color: #edf2f3;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .fts-todo-small:hover {
    cursor: pointer;
  }
}
