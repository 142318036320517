@import "../node_modules/prismjs/themes/prism-tomorrow";
@import "./assets/scss/app.scss";

body {
}
.paypal-buttons {
  display: none;
}
nav.react-contextmenu {
  position: fixed;
  min-width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.fts-link {
  color: $primary;
  font-size: 14px;
  cursor: pointer;
}

.Toastify__toast-container {
  width: auto !important;
  min-width: 320px !important;
}

.Toastify__toast--info {
  opacity: 1 !important;
}

.Toastify__close-button--info {
  color: black !important;
  font-size: 16px !important;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.notification-header-unread {
  .unread {
    background-color: #01b5f5;
    border-radius: 4px;
  }
  svg {
    g {
      stroke: white;
      fill: white;
      stroke-width: 0.5px;
    }
  }
}
.react-contextmenu-item span {
  margin-left: 10px;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.react-contextmenu-item .copy {
  color: skyblue;
}

.ag-row-odd {
  background-color: #f3f6f9;
}

// .row-update-red {
//   background-color: #fff5f7;
// }

.row-active {
  // background-color: #f6fdff;
  border-left: 3px solid #419d6d !important;
  border-radius: 3px;
}

.row-deleted {
  // background-color: #f6fdff !important;
  // opacity: 0.5 !important;
  border-left: 3px solid #f6fdff !important;
  border-radius: 3px !important;
}

.row-draft {
  // background-color: #fef9f1;
  border-left: 3px solid #f5b053 !important;
  border-radius: 3px;
}

.row-disabled {
  // background-color: #f8f8f8;
  border-left: 3px solid #a6b0ba !important;
  border-radius: 3px;
}

// .row-gray-out {
//   color: gray !important;
// }

.row-warning {
  // background-color: #fff5da;
  // color: gray !important;
  border-left: 3px solid #f5d276 !important;
  border-radius: 3px;
}

.row-suspended {
  // background-color: #fff5f5;
  border-left: 3px solid #f36565 !important;
  border-radius: 3px;
}

.row-paid {
  border-left: 3px solid #419d6d !important;
  border-radius: 3px;
}

.row-unsuccessful {
  border-left: 3px solid #5d6d7c !important;
  border-radius: 3px;
}
.row-overdue {
  border-left: 3px solid #dd6164 !important;
  border-radius: 3px;
}
.row-pending {
  border-left: 3px solid #fdca49 !important;
  border-radius: 3px;
}

.row-voided {
  border-left: 3px solid #0077cd !important;
  border-radius: 3px;
}

.react-contextmenu-wrapper {
  min-width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.ag-react-container {
  min-width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

.spinner-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
}

.row-unread {
  background-color: #ffffff;
  border-left: 5px solid #ce1b3b !important;
  border-radius: 5px;
}

.row-read {
  background-color: #f2f3f4;
}

//
//.column-item {
//  top: 0px;
//  opacity: 1;
//}
//
//.transform-column-item {
//  transition: all 5s;
//  top: 50vh;
//  opacity: 0;
//  position: absolute;
//}

.transform-column-item-down {
  //.fts-todo-wrapper {
  //  .fts-todo-small {
  //    .fts-todo-small-body {
  //    }
  //  }
  //}

  animation: fadeOutFromNone 2s linear;
  top: var(--mouse-y);
  z-index: 200;
}

@keyframes fadeOutFromNone {
  0% {
    transform: scale(1);
    opacity: 1;
    top: var(--mouse-y);
  }
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: translate(0px, 0px) scale(0.7);
    //transform: scale(0.7);
    //position: absolute;
    //opacity: 1;
    //top: var(--mouse-y);
  }
  100% {
    transform: translate(0px, 50vh) scale(0.7);
    position: absolute;
    opacity: 0.7;
  }
}

.transform-column-item-up {
  //.fts-todo-wrapper {
  //  .fts-todo-small {
  //    .fts-todo-small-body {
  //    }
  //  }
  //}

  animation: fadeOutToUp 2s linear;

  z-index: 200;
}

@keyframes fadeOutToUp {
  0% {
    transform: scale(1);
    opacity: 1;
    top: var(--mouse-y);
  }
  25% {
    transform: scale(0.7);
  }
  50% {
    transform: translate(0px, 0px) scale(0.7);
    //transform: scale(0.7);
    //position: absolute;
    //opacity: 1;
    //top: var(--mouse-y);
  }
  100% {
    transform: translate(0px, -50vh) scale(0.7);
    position: absolute;
    opacity: 0.7;
  }
}

.task-management__container {
  height: 80vh !important;
}

.bg-popover {
  background: #4b5457;
  border-radius: 4px;

  span {
    display: none !important;
  }
}

.bg-void-popover {
  background: #4b5457;
  border-radius: 4px;
  span {
    color: #fff !important;
  }
}

.ag-overlay-wrapper {
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.01) !important;
}

.Toastify__toast--info {
  border-radius: 4px !important;
}

.Toastify__toast-body {
  color: #fff;
}

#listing_item_descriptions * {
  all: revert !important;
  margin: 0 !important;
  //font-family: "Kumbh Sans", sans-serif !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

iframe {
  border: unset;
}
