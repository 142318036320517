.FTS-price-amount {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: max-content;
  min-width: 100px;

  .FTS-price-icon {
    margin-right: 10px;
  }

  .FTS-price-amount-content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .FTS-price-amount-label {
    color: #7d8c91;
    font-size: 11px;
  }

  .FTS-price-amount-number {
    color: #57c990;
    font-size: 20px;
    line-height: 24px;
  }
}
