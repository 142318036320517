@import "../core/variables/variables";

.sales-flex-wrapper {
  .ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus {
    z-index: 999;
  }

  .ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
    z-index: 0;
  }

  .fts-account-tab-wrapper {
    padding: 0;
  }

  .ag-cell {
    line-height: unset !important;

    .select__control {
      min-height: 24px !important;
    }

    .select__menu {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      min-width: 200px;
      width: max-content;
    }

    overflow: visible !important;
  }

  .nav-link.active {
    svg {
      path {
        stroke: #01b5f5 !important;
        fill: none !important;
      }
    }
  }

  .fts-data-list-sidebar {
    .card {
      box-shadow: none;
    }
    .card-body {
      padding-right: 0 !important;
    }
    .row-100 {
      width: 100%;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    border: none;
    border-radius: 0px;
    box-shadow: 0.2px 0 16px rgba(0, 0, 0, 0.08);
  }

  .collapsed-button:hover {
    cursor: pointer;
  }

  .collapsed-button {
    svg {
      transform: rotate(180deg);
    }
  }

  .fts-nav-container {
    overflow: hidden;
    padding-right: 10px;
    width: 42px;
    margin-right: 15px;
  }

  .fts-nav-container-collapsed {
    overflow: hidden;
    padding-right: 10px;
    width: 42px !important;
    margin-right: 15px;
  }

  .assigned-to-dropdown {
    width: 280px;
    margin-right: 1rem;
    z-index: 1000;

    .select__control {
      min-height: 44px !important;
    }
  }
}

.sales-lists {
  .card {
    background: #ffffff;
    margin-bottom: 2.2rem;
    border: none;
    border-radius: 0px;
    box-shadow: 0.2px 0 16px rgba(0, 0, 0, 0.08);
  }

  .ag-grid-table {
    height: calc(100vh - 19rem) !important;
  }

  .search-title {
    padding-bottom: 1rem;
    font-size: 16px;
    color: $fts-dark-gray;
  }

  .result-header {
    padding-top: 24px;

    .result-title {
      color: #242d34;
      font-size: 21px;
    }
  }
}

.bg-sales-popover {
  .bg-popover-wrapper {
    display: flex;
    flex-direction: column;
    width: 275px;
  }

  .arrow::before {
    display: none;
  }

  .arrow::after {
    border-bottom-color: #505454 !important;
  }

  .bg-popover {
    padding: 1rem 0 0px;
    display: flex;
    .bg-popover-title {
      color: #fff;
      font-weight: bold;
      line-height: 16px;
      font-size: 14px;

      margin-bottom: 1rem;
      margin-left: 1rem;
    }
    .bg-popover-cat-name {
      line-height: 17px;
      font-size: 13px;
      color: #fff;
      margin: 0;
      padding: 0;
      padding-bottom: 8px;
      margin-bottom: 8px;
      padding-left: 1rem;
    }

    .bg-popover-cat-name:not(:last-child) {
      border-bottom: 1px solid grey;
    }
  }
}
