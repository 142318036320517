.empty-todo-section-wrapper {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  justify-content: center;

  .messages-section {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;

    .title {

      font-size: 24px;
      line-height: 28px;
      font-weight: bold;
      margin-bottom: 12px;
    }

    .message {

      color: #647074;
      font-size: 14px;
      line-height: 16px;
    }

    .leads-pool-link {
      color: #01b5f5;
      margin-top: 9px;
    }

    .leads-pool-link:hover {
      cursor: pointer;
    }
  }

  .empty-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #fff;
    border: 1px solid #e5e8e9;

    .empty-icon-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 102px;
      height: 102px;
      border-radius: 50%;
      background-color: #f9fafc;

      svg {
        stroke: none;
      }
    }
  }
}

.todo-section-wrapper {
  margin-top: 1.5rem;

  .congrulations-todo-section {
    .fts-icon {
      stroke: unset;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .congrulations-header {
      display: flex;
      flex-direction: column;
      align-items: center;

      .message {

        color: #98ca86;
        margin-top: -12px;
      }
    }

    .congrulations-header:first-child {

      font-size: 14px;
      line-height: 16px;
      color: #32383a;
    }

    .task-management-link {
      display: flex;
      align-items: center;

      font-size: 14px;
      line-height: 16px;
      font-weight: normal !important;
      color: #01b5f5;

      svg {
        stroke: none;
        margin-left: 4px;
      }
    }

    .task-management-link:hover {
      cursor: pointer;
    }
  }

  .todo-types-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #f9fafc;
    margin-right: 1rem;
    padding: 10px 24px;

    .todo-type-row {


      font-size: 14px;
      line-height: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #32383a;
      height: 44px;
      padding: 14px;

      .todo-type-color {
        display: inline-block;
        border-radius: 2px;
        margin-right: 1rem;
        height: 12px;
        width: 12px;
      }
    }

    .todo-type-row:not(:last-child) {
      border-bottom: 1px solid #e5e8e9;
    }
  }

  .apexcharts-legend-text {

    font-size: 12px !important;
    line-height: 14px !important;
    color: #32383a !important;
    margin-left: 6px;
  }
}
