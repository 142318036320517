// .notifications-button {
//     transition: padding-left 0.35s ease 0s;
//     padding-top: 6px;
//     padding-bottom: 6px;

//     .menu-title {
//
//         color: #f3f3f3;
//         font-size: 15px;
//         line-height: 22px;
//         margin-left: -5px;
//     }
// }

// .notifications-button:hover {
//     cursor: pointer;
//     padding-left: 1rem;
// }

.notification-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.7rem;
  padding-top: 6px;
  padding-bottom: 6px;
  transition: padding-left 0.35s ease 0s;

  .notification-text {
    display: flex;
    align-items: center;

    color: #f3f3f3;
    font-size: 15px;
    line-height: 22px;
    margin-left: -5px;
  }

  .menu-icons {
    display: flex;
  }

  .text-content {
    display: flex;
    align-items: center;
    background-color: black;
  }

  .notification-number {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    background: rgba(167, 24, 49, 1);
    border-radius: 30px;
    padding: 2px 4px;
    min-width: 24px;
  }

  .closed-notification-indicator {
    background-color: #fff;
    color: #f36565;
    // padding: 1px 5px;
    border-radius: 40px;
  }
}

.notification-container:hover {
  cursor: pointer;
  padding-left: 1rem;
}

.notify {
  height: 7px;
  width: 7px;
  position: absolute;
  background: #00b5f4;
  top: -1px;
  right: 14px;
  border-radius: 10px;
}
