@import "../../core/variables/variables";

.user-notes-wrapper {
  margin-bottom: 7rem;
  .user-notes-header {
    padding: 2.5rem 2.5rem 0 2.5rem;
    margin: 0;

    hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .notes-header-item {
      width: 20rem;
      margin-right: 1rem;
    }
  }

  .user-notes-data-list {
    padding: 0.5rem 2.5rem 0 2.5rem !important;

    .show-more-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .show-more-wrapper:hover {
      cursor: pointer;
    }
  }

  .user-notes-data {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0.5rem 1.5rem 0 2.5rem;
    margin: 0;
    max-height: 60vh;
    overflow: auto;
    overflow-x: hidden;

    .fts-sticky {
      margin: 0 0 1rem 0;

      .fts-linked-text {
        color: $primary;
        font-size: 14px;
      }
    }

    .empty-text {
      margin: 0;
      display: flex;
      justify-content: center;
      width: 100%;
      padding-bottom: 1.5rem;
      color: #626262;
    }
  }

  .user-notes-bottom {
    background-color: #f8f8f8;
    padding: 1rem 1rem 0 1rem;
    .form-group {
      margin: 0;
    }
  }
}
