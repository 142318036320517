.user-info-container {
  display: flex;
  padding: 10px;
  color: white;
  padding-bottom: 1.8rem;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.5rem;
  border-bottom: 1px solid #EC5A75;
  height: 80px;
  margin-bottom: -0.387rem;
  margin-left: 0.65rem;
  margin-right: 0.65rem;
  padding-left: 0.3rem;
  flex-direction: row;

  .main-user-info {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .user-avatar {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border: 1px solid #EC5A75;
    padding: 3px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .inner-circle {
      background-color: #EC5A75;
      width: 34px;
      height: 34px;
      min-width: 34px;
      min-height: 34px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      line-height: 18px;
      color: #fff;
    }
  }

  .user-text-content {
    display: flex;
    flex-direction: column;
    margin-left: 0.9rem;
    margin-bottom: -2px;
    .full-name {
      white-space: nowrap;
    }
  }

  .role-text {
    font-size: 1.1rem;

    font-weight: 400;
  }

  .opened {
    transform: rotate(-90deg) !important;
    transition: all 0.25s ease;
  }

  .closed {
    transform: rotate(90deg) !important;
    transition: all 0.25s ease;
  }
}

.test-drop:after {
  display: none;
}

.user-info-dropdown {
  top: -25px !important;
  width: 230px;
  left: 10px !important;
  a {
    text-align: center;
  }
}

.user-info-dropdown::before {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
