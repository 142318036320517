.filter-wrapper {
  background-color: #f7f9fb;
  box-shadow: 0px 2px 16px #e8ebf1;
  border-radius: 4px;
  .range-picker {
    font-weight: bold;
  }
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin-left: -19px;
    margin-right: 6px;
    height: 71px;
  }

  .left {
    width: 25%;
    margin-left: 14px;
  }

  .range {
    width: 270px;
    border-radius: 1px;
    height: 41px;

    .clear-calendar-button {
      margin-right: 23px;
      transition: 3000ms color ease;
      svg {
        stroke: #ccc;
      }
    }

    .clear-calendar-button:hover {
      svg {
        stroke: #999;
      }
    }

    .form-control-position {
      z-index: 3;
    }

    .fts-date-picker {
      background-color: #fbfcfd !important;
    }
  }

  .flatpickr-input {
    background-color: transparent !important;
  }

  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .switch-wrapper {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .card {
    margin-top: -15px;
    margin-bottom: 17px;
    padding: 0px 5px 0px 5px;
    box-shadow: none !important;
  }

  .card-body {
    padding: 0;
    margin: 0px;
  }

  .row {
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: -19px;
    margin-right: -19px;
  }

  .column {
    height: 40px;
    width: 19%;
    border-radius: 1px;
    margin-left: 1rem;
  }

  .button {
    background-color: #01b5f5;
    color: white;
    padding: 0 1rem 0 1rem;
    width: 17%;
    line-height: auto;
    margin-top: 1px;
    height: 48px;
    font-weight: bold;

    font-size: 14px;
    margin-left: 1rem;
    border: none;
    border-radius: 4px;
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: max-content;
    background-color: #f1f5fa;
    height: 40px;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 4px;
  }

  .tab {
    height: 32px;
    width: 75px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    font-weight: normal !important;

    font-size: 14px;
    margin-left: 4px;
    margin-right: 4px;
    outline: none;
  }

  .tab:focus {
    outline: 0 !important;
  }

  .selected {
    border: none;
    background-color: #ffffff;
    color: #242d34;
    box-shadow: 0px 2px 5px #cdd8e6;
  }

  .unselected {
    background-color: transparent;
    border: none;
    color: #7d8c91;
  }

  a.nav-link-search {
    float: left;
    color: grey;
  }

  .search-input {
    float: left;
    width: 0;
    input {
      width: 0;
      border: none;
      transition: all 0.2s ease-out;
      line-height: 16px;
      padding: 1.75rem 3.6rem;
      &:focus {
        outline: 0 !important;
        outline-offset: 0 !important;
      }
    }
    &.open {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 1;
      border-radius: 0.5rem;
      .search-input-close,
      .search-input-icon {
        display: block;
      }
      input {
        width: 100%;
        outline: none;
        height: 75px;
        transition: all 0.3s ease-out;
      }
    }
    .search-input-icon {
      z-index: 2;
      display: none;
      position: absolute;
      left: 1.5rem;
      top: 1.75rem;
      cursor: pointer;
    }

    .search-input-close {
      z-index: 1;
      display: none;
      position: absolute;
      right: 2rem;
      top: 25px;
      cursor: pointer;
    }
    .search-header {
      padding: 1.5rem 1.5rem;
      padding-bottom: 0.5rem;
    }
    .other-results {
      overflow: hidden;
      .permanent-result {
        width: 100%;
        cursor: pointer;
        padding: 0.5rem;
        padding-left: 1rem;
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
}
