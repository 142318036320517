.dropdown-wrapper {
  .dropdown {

    padding: 8px 0 8px 0;
    width: 250px;
    position: absolute;
    z-index: 100;

    background-color: #ffffff;
    border: 1px solid #e3e7eb;
    box-shadow: 0px 2px 5px #e3e7f0;
    border-radius: 2px;

    .fts-icon {
      fill: none !important;
    }

    .dropdown-item {
      appearance: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: none;
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      line-height: 16px;
      font-size: 13px;
      color: #32383a;
      font-weight: normal;
      padding: 12px 0.3rem 12px 0.7rem;
      border-radius: 2px !important;

      svg {
        stroke: #7d8c91 !important;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: normal;
      }
    }

    .dropdown-check {
      .fts-icon {
        height: 20px;
        width: 24px;
        path {
          // stroke: #01b5f5 !important;
          fill: #01b5f5 !important;
        }
      }
    }

    .dropdown-icons {
      .fts-icon {
        height: 17px;
        width: 17px;
        path {
          stroke: #242d34 !important;
        }
        margin-right: 10px;
      }
    }

    .dropdown-disabled-icons {
      .fts-icon {
        height: 17px;
        width: 17px;
        path {
          stroke: #adb5c2 !important;
        }
        margin-right: 10px;
      }
    }

    .dropdown-item:hover {
      transition: all 0.3s ease;
      background-color: #f2f6f9;
      color: #242d34;
      cursor: pointer;

      .dropdown-icons {
        path {
          stroke: #242d34 !important;
        }
      }
    }

    hr {
      margin: 0.5rem 0;
      border-top: 1px solid #edf2f3 !important;
    }
  }

  .dropdown-red {
    color: #ce1b3b !important;
    .fts-icon {
      margin-right: 10px;
      height: 17px;
      width: 17px;
    }
  }

  .enabled {
    path {
      stroke: black;
    }
  }

  .disabled {
    cursor: default !important;
    color: #adb5c2 !important;
    path {
      stroke: #adb5c2 !important;
    }

    a {
      cursor: default !important;
      pointer-events: none !important;
    }
  }

  .gray-button {
    padding: 0 !important;
    width: 48px;
    height: 48px;
  }

  .gray-button:focus {
    background-color: transparent !important;
  }

  .dropdown-button {
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    border: transparent;
    padding-top: 0.5rem;
    padding-right: 0;
    svg {
      height: 15px;
      stroke: #9ca6a9;
      fill: #9ca6a9 !important;
      stroke-width: 1.5 !important;
      margin-right: 0 !important;
      transform: scale(1.4);
    }
  }

  .dropdown-button-active {
    svg {
      stroke: #01b5f5 !important;
      fill: #01b5f5 !important;
    }
  }
}

.dropdown-custom-right {
  margin-left: 0px;
  margin-top: 8px;
}

.dropdown-custom-left {
  margin-left: -215px;
  margin-top: -4px;
}

.dropdown-custom {
  .dropdown-item {
    margin-left: auto;
    margin-right: auto;
    width: 92% !important;
    border-radius: 2px;
  }
}

.dropdown-users {
  .dropdown-item {
    margin-left: auto;
    margin-right: auto;
    width: 92% !important;
    border-radius: 2px;
  }
}

.dropdown-types-right {
  left: 248px;
  top: 43px;
}

.dropdown-types-left {
  right: 248px;
  top: 43px;
}

.dropdown-users-right {
  left: 248px;
  top: 82px;
}

.dropdown-users-left {
  right: 248px;
  top: 82px;
}
