@import "../../core/variables/variables";

.category-wrapper-col {
  display: block;

  .divider {
    margin-bottom: 0;
  }

  .category-wrapper {
    display: flex;
    flex-direction: column;

    background-color: white;
    border: 1px solid #e5ebed;
    border-radius: 2px;
    margin-bottom: 2rem;
    padding: 1.1rem 0.85rem;
    transition: all 0.3s ease;

    &.active {
      border-color: #9ca6a9;
    }

    &.disabled {
      border: none;
      padding: 0;
    }

    .animation-wrapper {
      margin: 0;

      visibility: hidden;
      max-height: 0px;
      transition: max-height 0.5s ease;

      .inner-wrapper {
        opacity: 0;
        transition: opacity 0.3s ease;

        &.active {
          transition-delay: 0.2s;
          opacity: 1;
        }
      }
    }

    .animation-wrapper.active {
      visibility: visible;
      max-height: 600px;
      transition: max-height 0.5s ease;
    }

    .subtitle {

      margin-top: 0.25rem;
      color: #7e7e7e;
      margin-bottom: 1rem;
    }

    .category-label {
      font-size: 1.2rem;
    }
  }
}
