$verify: #F36565;
$payment: #F7C27A;
$renewal: #76BF92;
$reminder: #8884FF;
$follow_up: #7D8C91;
$secondary: #D3D3D3;


.todo-badge {
    display: inline;
    margin-right: 4px;
    width: auto;
    height: 18px;
    color: #FFFFFF;
    font-size: 80%;
    font-weight: normal;
    border-radius: 0.13rem;
    padding: 0.35rem 0.5rem;
}

.todo-verify {
    background-color: $verify;
    border-color: $verify;
}

.todo-payment {
    background-color: $payment;
    border-color: $payment;
}

.todo-renewal {
    background-color: $renewal;
    border-color: $renewal;
}

.todo-reminder {
    background-color: $reminder;
    border-color: $reminder;
}

.todo-follow_up {
    background-color: $follow_up;
    border-color: $follow_up;
}

.todo-secondary {
    background-color: $secondary;
    border-color: $secondary;
}