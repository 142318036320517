.invoices-wrapper {
  .invoices-date-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 18px;
  }

  .range {
    width: 270px;
    border-radius: 1px;
    height: 48px;

    .clear-calendar-button {
      margin-right: 23px;
      transition: 3000ms color ease;
      svg {
        stroke: #ccc;
      }
    }

    .clear-calendar-button:hover {
      svg {
        stroke: #999;
      }
    }

    .form-control-position {
      z-index: 3;
    }

    .fts-date-picker {
      background-color: #fbfcfd !important;
    }
  }

  .flatpickr-input {
    background-color: transparent !important;
  }

  .header-invoices-buttons {
    display: flex;
    margin-right: 1rem;
    padding: 0px;

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #f1f5fa;
      height: 48px;
      margin-right: 1rem;
      border-radius: 4px;
      padding-left: 6px;
      padding-right: 6px;
    }

    .tab {
      height: 40px;
      width: 174px;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      font-weight: normal !important;

      font-size: 14px;
      line-height: 16px;
      outline: none;
    }

    .tab:focus {
      outline: 0 !important;
    }

    .selected {
      border: none;
      background-color: #ffffff;
      color: #242d34;
      box-shadow: 0px 2px 5px #cdd8e6;
    }

    .unselected {
      background-color: transparent;
      border: none;
      color: #7d8c91;
    }
  }

  .btn-secondary {
    color: #8b97a3 !important;
    background-color: transparent !important;
    outline: none;
    border: none;

    &:hover {
      color: #8b97a3 !important;
      outline: none;
      border: none;
    }
    &:focus {
      color: #8b97a3 !important;
      background-color: transparent !important;
      outline: none;
      border: none;
    }
  }
}

.FTS-selected-invoices-card {
  margin-top: 20px;
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #99e1fb;
  background-color: #f1fbff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .FTS-selected-invoices-buttons {
    display: flex;
  }
}

.invoices-flex-wrapper {
  .ag-row.ag-row-level-0.ag-row-position-absolute.ag-row-focus {
    z-index: 999;
  }

  .ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
    z-index: 0;
  }

  .fts-account-tab-wrapper {
    padding: 0;
  }

  .ag-cell {
    line-height: unset !important;

    .select__control {
      min-height: 24px !important;
    }

    .select__menu {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      min-width: 200px;
      width: max-content;
    }

    overflow: visible !important;
  }

  .nav-link.active {
    svg {
      path {
        stroke: #01b5f5 !important;
        fill: none !important;
      }
    }
  }

  .fts-data-list-sidebar {
    .card {
      box-shadow: none;
    }
    .card-body {
      padding-right: 0 !important;
    }
    .row-100 {
      width: 100%;
    }
  }

  .card {
    margin-bottom: 2.2rem;
    border: none;
    border-radius: 4px;
    border: 1px solid #e3e7eb;
    // box-shadow: 0.2px 0 16px rgba(0, 0, 0, 0.08);
  }

  .collapsed-button:hover {
    cursor: pointer;
  }

  .collapsed-button {
    svg {
      transform: rotate(180deg);
    }
  }

  .fts-nav-container {
    overflow: hidden;
    padding-right: 10px;
    width: 42px;
    margin-right: 15px;
  }

  .fts-nav-container-collapsed {
    overflow: hidden;
    padding-right: 10px;
    width: 42px !important;
    margin-right: 15px;
  }

  .ag-grid-table {
    height: calc(100vh - 21rem);
  }
}
