.fts-account-not-found {
  display: flex;
  flex-direction: column;
  height: 90vh;
  align-items: center;
  justify-content: center;

  svg {
    stroke: none;
  }

  &-text {
    font-size: 24px;
    color: #32383a;

    font-weight: 600;
    margin-top: 32px;
  }
}
