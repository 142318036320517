/*** User pages ***/
@import "../core/variables/variables";

.users-avatar-shadow {
  box-shadow: 2px 4px 14px 0 rgba(34, 41, 47, 0.4);
}

// aggrid actions dropdown
.actions-dropdown {
  .btn-group .btn {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14) !important;
    border-radius: 0.5rem !important;
  }
}
// date picker width
.react-datepicker-wrapper {
  width: 100%;
}

// User view Table
.permissions-table {
  td,
  th {
    padding: 0.5rem;
  }
}

// User view info
.users-page-view-table .user-info {
  div {
    padding: 0.5rem;
  }
  .user-info-title {
    min-width: 140px;
  }
}

.search-title {
  padding-bottom: 1rem;
  font-size: 16px;
  color: $fts-dark-gray;
}

.result-header {
  // padding-top: 24px;
  // padding-left: 24px;
  // padding-right: 24px;

  padding: 24px 24px 0px 24px;
}

.ag-header-cell-label .ag-header-cell-text {
  font-size: 13px;
  color: #7d8c91;
  font-weight: normal;
}

.fts-promotions-list {
  .ag-grid-table {
    // box-shadow: 0px 3px 9px #ededf3;
    height: calc(100vh - 23rem);
  }
}

.app-user-list {
  .search-card {
    background: #ffffff;
    border: 1px solid #e3e7eb;
    border-radius: 2px;
    box-shadow: none;
  }

  .result-title-payments {
    color: $fts-dark-gray;
    font-size: 21px;
    margin-top: 1.3rem;
    // margin-left: 1.1rem;
  }
}

.switch-filter {
  background-color: #fff;
  border-bottom: 1px solid #e5ebed;
  margin-bottom: 2px;

  &__wrapper {
    display: flex;
    gap: 24px;
    padding-left: 24px;

    button {
      height: 64px;
      text-align: center;
      appearance: none;
      outline: none;
      background-color: transparent;
      color: #7d8c91;
      font-size: 14px;
      text-transform: uppercase;
      border: none;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: transparent;
      }

      &.active {
        background-color: #fff;
        color: $primary;

        &::after {
          background-color: $primary;
        }
      }
    }
  }
}

.fts-table-group-header {
  display: flex;
  justify-content: space-between;
  padding: 0 24px;
  padding-bottom: 24px;

  &-label {
    font-size: 20px;
    line-height: 24px;
    color: #121a22;
  }
}

.result-title {
  color: $fts-dark-gray;
  font-size: 21px;
}

@media (max-width: 576px) {
  .ag-grid-table {
    .ag-paging-panel {
      height: 160px;
    }
    .sort-dropdown {
      margin-bottom: 1rem;
    }
  }
}
