// Component nav and nav/pill tabs

@mixin nav-tabs {
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
  position: relative;
  border-radius: 0;
  padding-left: 24px;
  // Basic nav item
  .nav-item {
    position: relative;
    .nav-link {
      color: $disabled-text-color !important;
      font-size: 1rem;
      border: none;
      min-width: max-content;
      font-weight: 450;
      padding-bottom: 1.05rem;
      border-radius: 0;
      &.active {
        border: none;
        position: relative;
        color: $primary;
        transition: all 0.2s ease;
        background-color: transparent;
        transform: translateY(-2px);
        &:after {
          content: attr(data-before);
          height: 2px;
          width: 100%;
          left: 0;
          position: absolute;
          bottom: 0;
          top: 100%;
          background-color: $primary !important;
          box-shadow: none !important;
          transform: translateY(0px);
          transition: all 0.2s linear;
        }
      }

      &.dropdown-toggle {
        &::after {
          top: 1px;
          left: 1px;
        }
      }

      &.disabled {
        color: $secondary;
      }
    }
  }
}

.nav {
  border-radius: 0.25rem;
  // for component navs
  // to wrap border around nav component
  &.wrap-border {
    border: 1px solid $nav-component-border-color;
    li.nav-header {
      margin: 0 0.5rem;
    }
    li.nav-item,
    div {
      padding: 2px 0.714rem;
    }
  }

  // Square Border
  &.square-border {
    border-radius: 0;
    .nav-item {
      .nav-link.active {
        border-radius: 0;
      }
    }
  }

  // for main menu toggle lock
  .modern-nav-toggle {
    padding: 1.571rem 0;
    cursor: pointer;

    .toggle-icon {
      margin-right: 0.425rem;
    }
  }

  // nav pills
  &.nav-pills {
    margin-bottom: 1rem;
    // nav item of nav pill
    .nav-item {
      .nav-link {
        // font-weight: bold;
        display: flex;
        align-items: center;
        border-radius: 23px;
        height: 20px;
        padding: 0.5rem;
        font-size: 12px;
        margin-right: 0.5rem;
        color: $pure-black;
        &.active {
          color: $white;
        }
        &.disabled {
          color: $secondary;
        }
        &.dropdown-toggle::after {
          top: 1px;
          left: 1px;
        }
      }

      &.dropdown {
        &.show {
          .nav-link {
            color: $white;
          }
          .dropdown-item {
            &.active {
              &:hover {
                color: $primary;
              }
            }
          }
        }
      }
    }

    // Justified Pills
    &.nav-justified {
      @include nav-justified;
      @include nav-tabs-justified;
      .nav-link {
        display: block;

        &.active {
          border: none;

          &:hover,
          &:focus {
            border: none;
          }
        }
      }
    }

    // Nav Active Bordered Pill
    &.nav-active-bordered-pill {
      .nav-item {
        a {
          &.nav-link {
            &.active {
              border: 1px solid $primary;
              border-radius: 0.25rem;
              color: $primary;
              background-color: $white;
            }
          }
        }
      }
    }
  }

  // Basic Nav Tabs
  &.nav-tabs {
    @include nav-tabs;

    // Justified Tabs
    &.nav-justified {
      @include nav-justified;
      @include nav-tabs-justified;

      .nav-item {
        a.nav-link {
          display: block;
          border-radius: 0;

          &.active {
            border: none;
            background-color: transparent;
          }
          &:hover {
            border-color: transparent;
          }
        }
      }
    }
  }
}

// Nav Tabs Vertical
.nav-vertical {
  overflow: hidden;
  .nav.nav-tabs {
    // nav item styles for vertical nav tabs
    .nav-item {
      .nav-link {
        border: none;
        margin-bottom: 0;
        padding: 0.61rem 0.635rem;

        &.active {
          border: none;
          border-radius: 0;

          &:after {
            transform: rotate(90deg);
            left: -1rem;
            bottom: 100%;
            top: 1rem;
            width: 2.14rem;
          }
        }

        &:hover {
          border-right: none;
        }
      }
    }

    // vertical nav left tabs
    &.nav-left {
      float: left;
      display: table;
      margin-right: 1rem;

      ~ .tab-content {
        .tab-pane {
          display: none;
          background-color: $white;
          overflow-y: auto;
          padding-left: 1rem;
          &.active {
            display: block;
          }
        }
      }
    }

    // vertical right nav tabs
    &.nav-right {
      float: right;
      display: table;
      margin-left: 1rem;

      .nav-item {
        .nav-link {
          &.active {
            &:after {
              left: 2.6rem;
            }
          }
        }
      }

      ~ .tab-content {
        .tab-pane {
          display: none;
          background-color: $white;
          overflow-y: auto;
          padding-right: 1rem;
          &.active {
            display: block;
          }
        }
      }
    }
  }

  .nav-horizontal {
    .nav-tabs {
      @include nav-tabs;
    }
  }
}

.nav-tabs {
  box-shadow: none !important;
  border-bottom: 1px solid #e5ebed !important;
  gap: 24px;

  .nav-item {
    .nav-link {
      font-size: 14px !important;
      color: $disabled-text-color !important;
      padding-bottom: 15px;

      &.active {
        color: $primary !important;

        &:after {
          box-shadow: none !important;
          background-color: $primary !important;
        }
      }
    }
  }
}

.nav-vertical {
  overflow: hidden;
  .nav.nav-tabs {
    box-shadow: none !important;
    border-bottom: none !important;

    // nav item styles for vertical nav tabs
    .nav-item {
      .nav-link {
        color: #090a0a !important;

        &.active {
          color: $primary !important;
        }
      }
    }
  }
  .nav-horizontal {
    .nav-tabs {
      box-shadow: none !important;
      border-bottom: 1px solid #e5ebed !important;

      .nav-item {
        .nav-link {
          font-size: 14px !important;
          color: $disabled-text-color !important;

          &.active {
            color: $primary !important;

            &:after {
              box-shadow: none !important;
              background-color: $primary !important;
            }
          }
        }
      }
    }
  }
}
