.amenities-wrapper {
  .select {
    background-color: #fbfcfd;
    box-sizing: border-box;
    color: #2c3335;
    border: 1px solid #e3e7eb !important;
    border-radius: 1px;
    font-size: 1rem;
    min-height: 55.95px;
    line-height: 1.25;
    font-weight: 400;
  }

  .content {
    margin: 0;
    width: 100%;
    cursor: pointer;
    color: #9ca6a9;
    display: block;
    width: 100%;
    min-height: 27px;
    padding: 10px 0 2px 0;

    .react-dropdown-select-content {
      transition: all 0.5s ease;
      padding-left: 0px;
      padding-top: 8px;
    }

    .option {
      background-color: #edf2f3;
      font-size: 1.14rem;
      font-weight: 600;
      border-radius: 3px;
      padding: 2px 8px 2px 8px;
      margin-top: 3px;
      margin-right: 8px;
    }

    .option:hover {
      .option-label {
        color: #656c70;
      }
    }

    .option-label {
      color: #242d34;
      font-size: 13px;
      font-weight: bold;
    }

    .option-remove {
      cursor: pointer;
      font-size: 12px;
      padding-left: 5px;
    }

    .option-remove:hover {
      color: #ff4637;
    }

    .placeholder {
      font-size: 16px;
      color: #8a949d;
      position: absolute;
      transform: translateY(-5px);
      transition: all 0.3s ease;

      &.isFocused {
        color: #8a949d;
        font-size: 12px;
        transform: translateY(-6px);
      }
    }
  }

  .css-1aarvou-DropdownHandleComponent {
    margin: -3px 0px 0px 0px;
    color: #9ca6a9;
  }

  .css-1yc4zyy-DropdownHandleComponent {
    margin: 5px 0px 0px 0px;
    color: #9ca6a9;
  }

  .react-dropdown-select-content {
    padding-left: 0.9rem;
    font-size: 1.3rem;
    line-height: 1.25;
    transition: all 0.3s ease;

  }

  .css-wmw4vi-ReactDropdownSelect:focus-within {
    // box-shadow: 0 0 5pt 1.5pt #d3d3d3 !important;
    border: 1px solid #01b5f5 !important;
    box-shadow: none !important;
    background-color: #fff;
  }

  .css-w3djcp-OptionComponent {
    background-color: #edf2f3;
    color: #242d34;
    font-size: 1rem;
    font-weight: bold;
  }

  .items {
    overflow: auto;
    min-height: 10px;
    max-height: 235px;
    // border: 1px solid #01b5f5 !important;

    .icon-holder {
      position: relative;
      // top: 10px;
      .fts-icon {
        position: absolute;
        left: 20px;
        top: 15px;
      }
    }

    .input-holder {
      position: relative;
    }

    .form-control {
      width: 96%;
      margin: 8px;
      padding-left: 38px;
    }
  }

  .item {
    display: flex;
    margin: 10px;
    align-items: center;
    cursor: pointer;
  }

  .item-label {
    margin: 5px 10px;
  }
}
