.dashboard-tab-wrapper {
  height: auto;

  hr {
    margin-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid #e5e8e9;
  }

  .chart-card {
    background-color: transparent;
    border: 1px solid #e3e7eb;
    border-radius: 4px;
    margin-top: 24px;
    padding: 24px;
  }

  .chart-card-white {
    background-color: #fff;
    box-shadow: 0px 2px 4px 0px rgba(229, 232, 233, 1);
    border-radius: 4px;
    margin-top: 24px;
    padding: 24px;

    .title-row {
      display: flex;
      align-items: center;

      font-weight: bold;
      letter-spacing: 0.64px;
      color: #32383a;
      border-left: 4px solid #f30833;
      margin-left: -24px;
      padding-left: 20px;
      font-size: 12px;
      line-height: 14px;
      overflow: auto;
      height: 24px;

      .task-management-link {
        display: flex;
        align-items: center;

        font-size: 14px;
        line-height: 16px;
        font-weight: normal !important;
        color: #01b5f5;

        svg {
          stroke: none;
          margin-left: 4px;
        }
      }

      .task-management-link:hover {
        cursor: pointer;
      }
    }
  }

  .heading {
    color: #32383a;
    h2 {

      font-weight: bold;
      font-size: 29px;
      line-height: 32px;
    }

    .user-message span:first-child {

      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }

    .user-message span:last-child {

      font-size: 20px;
      line-height: 24px;
    }
  }

  .nav {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: max-content;
    background-color: #f1f5fa;
    height: 48px;
    margin-left: 1rem;
    border-radius: 4px;
  }

  .tab {
    height: 40px;
    width: 75px;
    border-radius: 4px;
    margin: 0;
    padding: 0;
    font-weight: normal !important;

    font-size: 14px;
    margin-left: 4px;
    margin-right: 4px;
    outline: none;
  }

  .tab:focus {
    outline: 0 !important;
  }

  .selected {

    border: none !important;
    background-color: #ffffff;
    color: #242d34;
    box-shadow: 0px 2px 5px #cdd8e6;
  }

  .unselected {
    background-color: transparent;
    border: none;
    color: #7d8c91;
  }

  .dashboard-spinner-wrapper {
    margin-left: 1rem;
    margin-bottom: 3px;
  }
}
