/***** React Paginate Styles *****/

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../core/variables/components-variables";

.vx-pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.5rem;

  li {
    display: inline-block;
    background-color: $pagination-bg-color;
    user-select: none;
    a {
      padding: 0.65rem 0.911rem;
      border: none;
      color: rgba($pure-black, 0.6);
      margin-left: 0;
      font-size: 1rem;
      font-weight: 700;
      display: block;
      line-height: 1.25;
      width: 100%;
    }
    &.active {
      a {
        border-radius: 0.5rem;
        background-color: $primary;
        color: $white;
        transform: scale(1.05);
      }
    }

    &.disabled {
      background-color: $body-bg;
      a {
        color: rgba($pure-black, 0.25);
      }
    }
    &:not(.disabled):not(.active):hover {
      a {
        color: $primary;
      }
    }
  }

  & li:first-child {
    border-top-left-radius: 1.428rem;
    border-bottom-left-radius: 1.428rem;
  }
  & li:last-child {
    border-top-right-radius: 1.428rem;
    border-bottom-right-radius: 1.428rem;
  }
  &:not(.icon-text-pagination):not(.icon-pagination) {
    li {
      &:first-child {
        &.previous {
          & + li {
            border-top-left-radius: 1.428rem;
            border-bottom-left-radius: 1.428rem;
            &.active {
              border-top-left-radius: 1.428rem;
              border-bottom-left-radius: 1.428rem;
            }
          }
        }
      }

      &:nth-last-child(2) {
        border-top-right-radius: 1.428rem;
        border-bottom-right-radius: 1.428rem;
        &.active {
          border-top-right-radius: 1.428rem;
          border-bottom-right-radius: 1.428rem;
        }
      }
    }
  }
  /***** Styles *****/
  &.basic-pagination {
    .previous,
    .next {
      display: none;
    }
  }

  &.separated-pagination {
    .previous,
    .next {
      border-radius: 50%;
      a {
        padding: 0.572rem 0.7rem;
      }
    }
    .previous {
      margin-right: 0.3571rem;
    }
    .next {
      margin-left: 0.3571rem;
    }
  }

  /***** Positions *****/
  &.pagination-start {
    justify-content: flex-start;
  }
  &.pagination-center {
    justify-content: center;
  }
  &.pagination-end {
    justify-content: flex-end;
  }

  /***** Sizes *****/
  &.pagination-lg {
    li {
      a {
        font-size: 1.5rem;
      }
      &.active {
        a {
          transform: scale(1);
        }
      }
    }
  }
  &.pagination-sm {
    li {
      a {
        padding: 0.5rem 0.75rem;
      }
      &.active {
        a {
          transform: scale(1.05);
        }
      }
    }
  }
}
