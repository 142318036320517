.services-wrapper {
  .select {

    font-weight: normal !important;
    box-sizing: border-box;
    color: #2c3335;
    border: 1px solid #e3e7eb !important;
    border-radius: 1px;
    min-height: 55.95px;
    font-size: 14px;
    line-height: 16px;
    background-color: #fbfcfd;
  }

  .css-1aarvou-DropdownHandleComponent {
    margin: -3px 0px 0px 0px;
    color: #9ca6a9;
  }

  .css-1yc4zyy-DropdownHandleComponent {
    margin: 5px 0px 0px 0px;
    color: #9ca6a9;
  }

  .react-dropdown-select-content {
    padding-left: 1rem;
    font-size: 1.14rem;
    line-height: 1.25;
    transition: all 0.5s ease;

    color: #757575;
  }

  .css-wmw4vi-ReactDropdownSelect:focus-within {
    // box-shadow: 0 0 5pt 1.5pt #d3d3d3 !important;
    background-color: #fff;
    border: 1px solid #01b5f5 !important;
    box-shadow: none !important;
  }

  .closed {
    border: 1px solid #b1b1b1 !important;
    box-shadow: none;
  }

  .css-w3djcp-OptionComponent {
    background-color: #edf2f3;
    color: #242d34;
    font-size: 1rem;
    font-weight: bold;
  }

  .items {
    overflow: auto;
    height: auto;
    // border: 1px solid #01b5f5 !important;

    .icon-holder {
      position: relative;
      // top: 10px;
      .fts-icon {
        position: absolute;
        left: 20px;
        top: 15px;
      }
    }

    .input-holder {
      position: relative;
    }

    .form-control {
      width: 96%;
      margin: 8px;
      padding-left: 38px;
    }

    .form-control {
      width: 96%;
      margin: 8px;

      &:focus {
        border: 1px solid #01b5f5 !important;
      }
    }
  }

  .item {
    display: flex;
    margin: 10px;
    align-items: center;
    cursor: pointer;
  }

  .item-label {
    margin: 5px 10px;
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 1rem;

    .category-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        font-size: 1.25rem;
        min-height: 40.95px;
        line-height: 1rem;
        margin-right: 0.5rem;
      }
    }
  }

  .red-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 40.95px;
    text-align: center;
    margin-left: -8px;
    padding: 0px 3px 0px 10px;
    border-bottom: 1px #efefef solid;
  }

  .red {
    width: 4px;
    margin-right: 14px;
    background-color: #ff4f57;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-weight: bolder;
    font-size: 1rem;
  }

  .open {
    border-radius: 1px;
    background-color: #f0ecec;
  }

  .chevron {
    margin-right: 1rem;
  }

  .content {
    margin: 0;
    width: 100%;
    cursor: pointer;
    color: #9ca6a9;
    display: flex;
    align-items: flex-end;
    height: 50px;

    .placeholder {
      color: #8a949d;
      position: absolute;
      transition: all 0.3s ease;
      transform: translateY(-15px);

      &.isFocused {
        transform: translateY(-30px);
        font-size: 12px;
      }
    }
  }

  .selected {
    transition: all 0.3s ease;
    transform: translateY(-7px);
    color: black;
  }
}
.services-wrapper-mini {
  .select {

    font-weight: normal !important;
    box-sizing: border-box;
    color: #2c3335;
    border: 1px solid #e3e7eb !important;
    border-radius: 1px;
    min-height: 38px;
    max-height: 38px;
    font-size: 14px;
    line-height: 16px;
    background-color: #fbfcfd;
  }

  .css-1aarvou-DropdownHandleComponent {
    margin: -3px 0px 0px 0px;
    color: #9ca6a9;
  }

  .css-1yc4zyy-DropdownHandleComponent {
    margin: 5px 0px 0px 0px;
    color: #9ca6a9;
  }

  .react-dropdown-select-content {
    padding-left: 1rem;
    font-size: 1.14rem;
    line-height: 1.25;
    transition: all 0.5s ease;

    color: #757575;
  }

  .css-wmw4vi-ReactDropdownSelect:focus-within {
    // box-shadow: 0 0 5pt 1.5pt #d3d3d3 !important;
    background-color: #fff;
    border: 1px solid #01b5f5 !important;
    box-shadow: none !important;
  }

  .closed {
    border: 1px solid #b1b1b1 !important;
    box-shadow: none;
  }

  .css-w3djcp-OptionComponent {
    background-color: #edf2f3;
    color: #242d34;
    font-size: 1rem;
    font-weight: bold;
  }

  .items {
    overflow: auto;
    height: auto;
    // border: 1px solid #01b5f5 !important;

    .icon-holder {
      position: relative;
      // top: 10px;
      .fts-icon {
        position: absolute;
        left: 20px;
        top: 15px;
      }
    }

    .input-holder {
      position: relative;
    }

    .form-control {
      width: 96%;
      margin: 8px;
      padding-left: 38px;
    }

    .form-control {
      width: 96%;
      margin: 8px;

      &:focus {
        border: 1px solid #01b5f5 !important;
      }
    }
  }

  .item {
    display: flex;
    margin: 10px;
    align-items: center;
    cursor: pointer;
  }

  .item-label {
    margin: 5px 10px;
  }

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-left: 1rem;

    .category-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        font-size: 1.25rem;
        min-height: 40.95px;
        line-height: 1rem;
        margin-right: 0.5rem;
      }
    }
  }

  .red-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 40.95px;
    text-align: center;
    margin-left: -8px;
    padding: 0px 3px 0px 10px;
    border-bottom: 1px #efefef solid;
  }

  .red {
    width: 4px;
    margin-right: 14px;
    background-color: #ff4f57;
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-weight: bolder;
    font-size: 1rem;
  }

  .open {
    border-radius: 1px;
    background-color: #f0ecec;
  }

  .chevron {
    margin-right: 1rem;
  }

  .content {
    margin: 0;
    width: 100%;
    cursor: pointer;
    color: #9ca6a9;
    display: flex;
    align-items: flex-end;
    height: 50px;

    .placeholder {
      color: #8a949d;
      position: absolute;
      transition: all 0.3s ease;
      transform: translateY(-15px);

      &.isFocused {
        transform: translateY(-30px);
        font-size: 12px;
      }
    }
  }

  .selected {
    transition: all 0.3s ease;
    transform: translateY(-7px);
    color: black;
  }
}
