.fts-admin-notification {
  height: 124px;
  border: 1px solid #57c990;
  background-color: #f2fff9;
  border-radius: 4px;
  width: 400px;
  color: #32383a;
  padding: 16px;

  &-content {
    display: flex;
    flex-direction: column;

    &-title {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      margin-bottom: 12px;

      svg {
        stroke: none;
        margin-right: 10px;
      }
    }

    &-notification {

      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.fts-admin-notification-negative {
  border: 1px solid #f36565;
  background-color: #fbeef1;
}

.fts-admin-notification-warning {
  border: 1px solid #efd58f;
  background-color: #fff6df;
}

.fts-admin-notification-info {
  border: 1px solid #34c4f7;
  background-color: #e6f8fe;
}

.Toastify__toast--info {
  box-shadow: 0px 4px 16px rgba(36, 45, 52, 0.2);
}
