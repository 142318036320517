.extend-validity-menu {
    display: flex;
    flex-direction: column;

    h2 {

        color: #8C919F;
        margin-bottom: 24px;
        font-size: 12px;
        line-height:16px;
    }

    .extend-validity-date {

        color: #32383A;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 28px;
    }

    .extend-validity-button {
        width: 100%;
        height: 48px;
        margin-top: 1rem;
    }
}
