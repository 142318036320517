.fts-account-access-request-modal {
  .modal-content {
    overflow: unset;
  }

  &-body {
    display: flex;
    flex-direction: column;
    padding: 32px 40px;
    height: auto;

    &-close {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      svg {
        stroke: none;
        cursor: pointer;
        margin-right: -10px;
        path {
          fill: #7d8c91;
        }
      }
    }

    &-content {
      width: 100%;
      display: flex;

      #lock-icon {
        margin-right: 32px;
      }

      h3 {
        margin-top: 8px;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        color: #32383a;
        margin-bottom: 14px;
      }

      p {
        color: #32383a;
        margin-bottom: 32px;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
      }

      .form-group {
        margin-bottom: 0px;
        label {
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;
          color: #4b5457;
          margin-bottom: 10px;
        }
      }
    }
  }
  .modal-footer {
    padding: 24px 48px;

    svg {
      margin-right: 8px;

      path {
        stroke: #fff;
      }
    }
  }
}
