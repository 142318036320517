
.upload-update-popover {
  z-index: 1000;
  border-radius: 2px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.141472);
  background-color: #fff;
  position: absolute;
  right: 35px;
  margin-top: -20px;

  .upload-popover-wrapper {
    display: flex;
    flex-direction: column;
    .upload-popover-icon {
      margin-right: 0.5rem;

      .fts-icon {
        stroke: none;
      }
    }

    button {

      color: #242d34;
      width: 150px;
      height: 44px;
      background-color: transparent;
      border: none;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding-left: 1rem;
    }
    button:hover {
      background-color: #fafafc;
      border: none;
    }
  }

  padding: 0;
}
