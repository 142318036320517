.fts-reassign-acc-list-modal {
  .fts-reassign-acc-list-modal-body {
    width: 100%;
    padding-bottom: 0;

    .select-all-checkbox {
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      margin-top: 1rem;
      height: 40px;

      .vx-checkbox {
        margin-right: 18px;
      }
      .vx-checkbox--check {
        background-color: #647074 !important;
      }
      .vx-checkbox-con input:checked ~ .vx-checkbox {
        border-color: #647074;
      }
    }

    .fts-reassign-acc-list-modal-body-wrapper {
      .reassign-rows-wrapper {
        border-top: 1px solid #ededed;
        background-color: #fafafc;
        width: 800px !important;
        margin-left: -40px;

        .empty-text {
          font-weight: 400 !important;
          font-size: 12px;
          line-height: 14px;
        }
      }

      .reassign-rows-wrapper .reassign-row {
        padding-left: 40px;
        padding-right: 40px;
        font-weight: bold;

        display: flex;
        justify-content: space-between;

        .listing-type-badge {
          border: 1px solid #e5e8e9;
          font-size: 12px;
          line-height: 14px;
          padding: 3px 10px;
          border-radius: 20px;
        }
      }
    }
  }
}
