.fts-table-tabs {
  display: flex;
  padding: 0 24px;
  gap: 24px;
  border-bottom: 1px solid #e5eaef;

  .fts-table-tab {
    padding: 12px 0px;
    cursor: pointer;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;

    &-active {
      border-bottom: 2px solid #00b5f4;
      color: #00b5f4;
    }
  }
}
