@import "../../../assets/scss/core/variables/variables";

.fts-sticky {
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 4px;
  background-color: #ffe999;
  margin-bottom: 3.5rem;

  .fts-linked-text {
    color: $primary;
    font-size: 14px;
  }

  &__flex-label {
    display: flex;
    align-items: center;
    .fts-badge {
      font-weight: bold;
    }
  }

  hr {
    width: 100%;
    border-top: 1px solid #fccc5c;
  }

  .fts-sticky-small-left-col {
    max-width: 3rem;
    padding-left: 0;
  }

  .more-button {
    appearance: none;
    padding: 0;
    background-color: transparent;
    border: none;
  }

  .fts-note-reply-btn {
    color: black;
    padding: 1rem 0.5rem;

    svg {
      transition: stroke 0.3s ease;
    }

    span {

      margin-left: 0.5rem;
      color: $primary;
      transition: color 0.3s ease;
    }

    &:focus {
      color: white;

      svg {
        stroke: white;
        transition: stroke 0.3s ease;
      }

      span {
        color: white;
        margin-left: 0.5rem;
        transition: color 0.3s ease;
      }
    }
  }

  &__name {

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
  }
  &__row {
    display: flex;
    margin: 0px;
    padding: 0px;
  }
  &__column {
    display: flex;
    flex-direction: column;
  }
  &__block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
  }

  &__img {
    margin-right: 12px;

    img {
      border-radius: 30px;
      width: 32px;
      height: 32px;
    }
  }

  &__right {
    margin-left: auto;
    .fts-linked-text {
      color: $primary;
      font-size: 14px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  &__left {
    margin-right: auto;
  }

  &__date {

    font-size: 12px;
    line-height: 14px;
    color: #898663;
  }
  .between {
    display: flex;
    padding-top: 5px;
  }
  .mrl {
    margin-left: 10px;
  }
  .mrb {
    margin-bottom: 5px;
  }

  .plaintext {

    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: #242d34;
    margin: 0px;
  }

  .sticky-indicator-label {
    color: #4b5457;
    border: 1px solid #c0c4c5;
    padding: 6px 4px;
    border-radius: 24px !important;
  }
}
