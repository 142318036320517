$title-row-offset-left: 2.5rem;
$title-row-border-width: 0.5rem;

.fts-title-row {
  margin-left: (-$title-row-offset-left) !important;
  margin-bottom: 1.5rem;

  .col h2 {
    margin: 0;
  }

  .col h4 {
    margin: 1px 0 1px 0;
  }

  .col:first-of-type {
    padding-left: ($title-row-offset-left - $title-row-border-width);
    border-left: $title-row-border-width solid #ff4f57;
  }

  &.no-marker {
    .col:first-of-type {
      padding-left: ($title-row-offset-left - $title-row-border-width);
      border-left-color: transparent;
    }
  }

  .go-to:hover {
    cursor: pointer;
  }
}

.fts-title-row:hover {
  cursor: default;
}

.fts-padded-title-row {
  margin: 0;
  padding: 0 24px;
}

@media (max-width: 575.98px) {
  .fts-title-row {
    margin-left: -1.5rem;
  }
}
