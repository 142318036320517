// Popovers

$po-border-radius: 2px;
$po-padding: 2rem;

.file-popover {
  // .popover {
  //   display: flex;
  // }
  // .popover-inner {
  //   width: 20rem;
  // }
  .popover {
    // display: table;
    min-width: 50vw;
    left: 3rem !important;
    z-index: 1040 !important;
  }

  .popover-header {
    // display: table;
  }

  .image-preview-btn {
    background-color: #00b5f4 !important;

    border-radius: 0.125rem;
    margin-right: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .popover-body {
    // display: table;
    max-width: 100%;

    .file-preview-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .file-resolution-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .image-resolution-text {


        .large-imge {
          color: #f36565 !important;
        }
      }
    }

    .file-preview {
      border: 1px solid #eeeeee;
      border-radius: 2px;
      max-width: 100%;
      margin-bottom: 1rem;
    }

    .file-versions-wrapper {
      max-height: 236px;
      overflow: auto;
    }

    .file-version {
      border-bottom: 1px solid #d8d8d8;

      padding: 0.6rem 0.3rem;
      padding-left: 0.8rem;
      cursor: pointer;

      &.current {
        padding-left: 0.5rem;
        border-left: 0.3rem solid $primary;
      }

      &__date {
        margin-top: 5px;
      }
    }
  }

  .arrow {
    left: 1.5rem !important;
  }
}

// popover header color and border-radius
.popover {
  .remove-btn-label {
    color: #ff4f57;
  }

  z-index: 1060;
  border-radius: $po-border-radius;
  border-color: transparent;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.21);
  padding: $po-padding;

  // .popover-inner {
  // }

  .popover-header {
    padding: 0;
    padding-bottom: $po-padding;
    background-color: white;
    border: none;

    &::before {
      display: none !important;
    }

    .fts-title-row {
      margin-left: -$po-padding;
      margin-bottom: 0;

      .col:first-of-type {
        padding-left: $po-padding - 0.5rem;
      }
    }
  }

  .arrow {
    &::before {
      border-color: transparent !important;
    }

    &::after {
      border-bottom-color: white !important;
    }
  }

  .popover-body {
    padding: 0;
  }
}
