.sales-reassign-wrapper {
  position: relative;
  .sales-reassign-dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border: 1px solid #d0d8e8;
    border-radius: 2px;
    padding: 12px 2rem;
    color: #01b5f5;
    transition: border-color 0.5s ease;

    font-size: 15px;
    line-height: 18px;
  }

  .disabled {
    color: #d0d8e8;
  }

  .disabled:hover {
    border: 1px solid #d0d8e8 !important;
    cursor: default !important;
  }

  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .sales-reassign-dropdown:hover {
    cursor: pointer;
    border-color: #b1babd;
  }

  .reassign-dropdown {
    position: absolute;
    z-index: 1200;
    top: 49px;
    right: 0;
    width: 176px;
    border: 1px solid #7d8c91;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0.4px 0.4px 12px 0 rgba(0, 0, 0, 0.11);

    .employee {

      padding: 9px 8px;
      color: #242d34;
      font-size: 12px;
      line-height: 15px;
      height: 32px;
    }

    .employee:hover {
      cursor: pointer;
      background-color: #f1f1f1;
    }
  }
}
