.FTS-search-input-new {
  display: flex;
  border: 1px solid #e5eaef;
  border-radius: 4px;

  &-icon {
    padding: 12px;
  }

  input {
    border: none;
    height: 48px;
    outline: none;
  }
}
