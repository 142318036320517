.account-marketing {
  .fts-title-row .col h4 {
    font-size: 16px;
    line-height: 24px;

    font-style: none !important;
    text-decoration: none !important;
    color: #2c2c2c;
    font-weight: normal;
    font-style: unset;
    font-weight: bold !important;
    letter-spacing: 1.9px !important;
  }

  .header-marketing-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    color: #121a22;
  }

  .header-marketing-buttons {
    display: flex;
    justify-content: space-between;
    // padding: 0px;
    // padding-left: 24px;
    // margin-top: 24px;
    // margin-right: 24px;
    padding: 24px;

    .new-template-button {
      .button-body {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          stroke: none;
          margin-right: 11px;
        }
      }
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #f1f5fa;
      height: 48px;
      margin-right: 1rem;
      border-radius: 4px;
      margin-right: 1rem;
    }

    .tab {
      height: 40px;
      min-width: 200px;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      font-weight: normal !important;

      font-size: 14px;
      line-height: 16px;
      outline: none;
    }

    .tab:focus {
      outline: 0 !important;
    }

    .tab:first-child {
      margin-left: 4px;
      margin-right: 2px;
    }

    .tab:last-child {
      margin-left: 2px;
      margin-right: 4px;
    }

    .selected {
      border: none;
      background-color: #ffffff;
      color: #242d34;
      box-shadow: 0px 2px 5px #cdd8e6;
    }

    .unselected {
      background-color: transparent;
      border: none;
      color: #7d8c91;
    }
  }

  .ag-overlay-wrapper {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.01) !important;
  }

  .ag-grid-table {
    height: calc(100vh - 20rem);
  }
}

.account-marketing-actions {
  display: flex;
  span {
    color: #01b5f5;
    margin-right: 20px;
    svg {
      margin-right: 12px;
    }
  }
}

.bg-marketing-popover {
  .bg-popover-wrapper {
    display: flex;
    flex-direction: column;
    //min-width: 975px;
  }

  .arrow::before {
    display: none;
  }

  .arrow::after {
    border-bottom-color: #505454 !important;
  }

  .bg-popover {
    min-width: 320px;
    padding: 10px;
    display: flex;
    .bg-popover-title {
      color: #fff;
      font-weight: bold;
      line-height: 16px;
      font-size: 13px;

      margin-bottom: 1rem;
      margin-left: 1rem;
    }
    .bg-popover-cat-name {
      line-height: 17px;
      font-size: 13px;
      color: #fff;
      margin: 0;
      padding: 0;
      padding-bottom: 8px;
      margin-bottom: 8px;
      padding-left: 1rem;
    }

    .bg-popover-cat-name:not(:last-child) {
      border-bottom: 1px solid grey;
    }
  }
}
