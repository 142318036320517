.fts-reassign-modal {
  border-radius: 4px;

  .reassign-modal-footer {
    padding: 12px 27px;

    .downgrade-button {
      color: white !important;
      background-color:#F36565 !important;
    }
  }

  .modal-body {
    padding: 0;
  }

  .reassign-modal-body {
    display: flex;
    justify-content: space-between;
    padding: 40px;
    color: #32383a;

    .reassign-modal-body-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .fts-icon {
      stroke: none;
      margin-right: 24px;
    }

    .reassign-modal-title {
      display: flex;
      justify-content: space-between;
      padding: 0;

      .fts-close-btn {
        background-color: transparent;
        color: #7d8c91;
      }
    }

    .reassign-modal-message {

      font-size: 14px;
      line-height: 16px;
    }
  }
}
