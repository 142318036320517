.clear-calendar-button {
  transition: 3000ms color ease;
  svg {
    stroke: #ccc;
  }
}

.clear-calendar-button:hover {
  svg {
    stroke: #999;
  }
}

.form-control-position {
  z-index: 3;
}

.activity-icon {
  stroke-width: 2px !important;

  path {
    stroke-width: 2px !important;
  }
}
