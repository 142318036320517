.search-input-group {
  display: flex;
  align-items: center;
  padding: 0 17px;
  transition: all 0.3s ease;

  font-weight: normal !important;
  color: #8a949d;
  background: #fbfcfd;
  border: 1px solid #e3e7eb !important;
  box-sizing: border-box;
  border-radius: 2px;
}

.search-input-group-focused {
  border: 1px solid #00b5f4 !important;
  background: #ffffff;
  // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
}

.search-input {
  height: 56px;
  font-size: 14px;
  color: #8a949d;
  .icon {
    margin-right: 12px;
    svg {
      stroke: #9ca6a9;
    }
    transition: all 0.3s ease;
  }

  .placeholder {

    color: #8a949d;
    position: absolute;
    left: 0;
    pointer-events: none;
    font-size: 16px;
    transform: translateY(-36px);
    transition: all 0.3s ease;

    &.hasValue {
      font-size: 12px;
      color: #9ca6a9;
      transform: translateY(-15px);
    }
  }

  .isFocused {
    font-size: 12px;
    transform: translateY(-44px);
    color: #9ca6a9;
  }

  .icon-focused {
    margin-right: 12px;

    svg {
      stroke: #00b5f4;
    }
    // transition: all 0.3s ease;
  }

  &__input {
    align-self: stretch;
    flex: 1;

    &--placeholder {
      position: relative;
      padding-top: 17px;
    }

    textarea {
      width: 100%;
      appearance: none;
      height: 100%;
      border: none;
      outline: none;
      font-size: 12px;
      padding: 17px 0;
      resize: none;
      color: #242d34;
    }

    .input {
      width: 100%;
      appearance: none;
      height: 100%;
      border: none;
      outline: none;
      font-size: 14px;
      line-height: 16px;
      padding: 0;
      background-color: #fbfcfd;

      &:focus {
        background: #ffffff;
      }

      &::placeholder {
        color: #8a949d;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
        transition: background-color 5000s ease-in-out 0s;
      }

      &:not(:-webkit-autofill) {
      }

      &[disabled] {
        color: #222;
        background-color: transparent;
      }
    }
  }
}

@media (max-width: 1582px) {
  .search-input-sidebar-collapsed {
    .search-input__input--placeholder {
      margin-bottom: 1.5rem;
    }

    .input[value=""] {
      margin-top: 0.5rem !important;
    }

    .input[value=""]:focus {
      margin-top: 1rem !important;
    }

    input:not(:empty) {
      margin-top: 1rem;
    }

    .input {
      margin-top: 1rem;
    }

    .isFocused {
      padding-top: 0.4rem;
    }
  }
}
