.FTS-chart-wrapper {
  border-radius: 4px;
  width: 270px;
  width: 100%;
  overflow: auto;
  min-height: 460px;
  border: 1px solid #e5e8e9;
  background-color: #fff;
}
.FTS-chart {
  height: 300px;
  padding: 20px;
}
.labelWrapper {
  // @apply flex justify-between mb-[0.5rem];
  display: flex;
  justify-content: space-between;
}

.FTS-chart-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #e5e8e9;
  padding: 12px;
}
.label {
  font-size: 14px;
  line-height: 16px;
  color: #97a3a7;
}

.amount {
  font-size: 24px;
  line-height: 24px;
  color: #000;
}

.FTS-exposure-chart-legend {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 600px) {
  .FTS-chart-wrapper {
    width: 145px;
    margin-bottom: 10px;
  }
}
