.fts-reassign-modal {
    .update-listing-confrimation-modal-cta {
      display: flex;
      align-items: center;
      justify-content: space-between !important;
      padding: 13px 2rem;
      width: 100%;

      .back-button {
        display: flex;
        align-items: center;

        svg {
          margin-right: 6px;
          path {
            stroke: #626262 !important;
          }
        }
      }

      .vx-checkbox--check {
        background-color: #647074 !important;
      }
      .vx-checkbox-con input:checked ~ .vx-checkbox {
        border-color: #647074;
      }
    }

    .generate-report-confirmation-input {
        margin-top: -1.5rem;
        margin-bottom: 2rem;
        margin-right: 35px;
        margin-left: 110px;
        padding: 16px 12px;
    }

    .reassign-modal-body {
      .update-listing-confirmation-title {
        h3 {

        }
        span {

        }

        .confirmation-spinner-wrapper {
          margin-left: 1rem;
          margin-top: 2px;
        }
      }


    }
  }
