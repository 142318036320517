.fts-upgrades-page {
  .card {
    box-shadow: 0px 3px 9px 0px rgba(237, 237, 243, 1);
    border-radius: 4px;

    .fts-upgrade-title-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      padding: 24px 24px 0 24px;

      h4 {
        font-size: 20px;
        line-height: 24px;
      }

      .fts-upgrade-filters {
        display: flex;
        align-items: center;

        .nav {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: max-content;
          background-color: #f1f5fa;
          height: 48px;
          margin-left: 1rem;
          border-radius: 4px;
        }

        .tab {
          height: 40px;
          width: 45px;
          border-radius: 4px;
          margin: 0;
          padding: 0;
          font-weight: normal !important;

          font-size: 14px;
          margin-left: 4px;
          margin-right: 4px;
          outline: none;
        }

        .tab:focus {
          outline: 0 !important;
        }

        .selected {
          border: none;
          background-color: #ffffff;
          color: #242d34;
          box-shadow: 0px 2px 5px #cdd8e6;
        }

        .unselected {
          background-color: transparent;
          border: none;
          color: #7d8c91;
        }
      }
      .upgrades-search-input-field {
        width: 304px;
      }
    }

    .ag-grid-table {
      height: calc(100vh - 21rem);
    }
  }
}

.fts-new-listings {
  .ag-grid-table {
    height: calc(100vh - 25rem) !important;
  }
}

.fts-new-accounts {
  .ag-grid-table {
    height: calc(100vh - 25rem) !important;
  }
}
