.fts-access-request-badge {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  .icon {
    svg {
      path {
        stroke: #f36565;
      }
      margin-right: 6px;
      margin-bottom: 3px;
    }
  }

  .text {
    color: #f36565;
  }
}
