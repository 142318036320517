.note-quill {
  .ql-editor * {

    color: black !important;
    font-size: 14px !important;
    line-height: 17px !important;
    background-color: transparent !important;
  }

  .ql-editor.ql-blank::before {
    color: #7d8c91;
  }
}
