.timer-picker {

  width: 100%;

  .select {
    background: #fbfcfd;
    border: 1px solid #e3e7eb !important;
    box-shadow: none !important;
    border-radius: 4px;
    box-sizing: border-box;
    color: #32383a;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    min-height: 48px;
  }

  .select:focus {
    box-shadow: none !important;
  }

  .content {
    margin: 0;
    width: 100%;
    cursor: pointer;
    color: #9ca6a9;
    display: block;
    min-height: 27px;
    padding: 10px 0 2px 0;

    .react-dropdown-select-content {
      transition: all 0.5s ease;
      padding-left: 0px;
      padding-bottom: 8px;
    }

    .option {
      background-color: #edf2f3;
      font-size: 1.14rem;
      font-weight: 600;
      border-radius: 3px;
      padding: 2px 8px 2px 8px;
    }

    .option:hover {
      .option-label {
        color: #656c70;
      }
    }

    .option-label {
      color: #242d34;
      font-size: 13px;
      font-weight: bold;
    }

    .option-remove {
      cursor: pointer;
      font-size: 12px;
      padding-left: 5px;
    }

    .option-remove:hover {
      color: #ff4637;
    }

    .placeholder {

      font-weight: 500;
      font-size: 14px;
      line-height: 19px;

      color: #32383a;
      position: absolute;
      transform: translateY(-5px);
      transition: all 0.3s ease;

      &.isFocused {
        display: none !important;
      }
    }
  }

  .css-1aarvou-DropdownHandleComponent {
    margin: -3px 0px 0px 0px;
    color: #9ca6a9;
  }

  .css-1yc4zyy-DropdownHandleComponent {
    margin: 5px 0px 0px 0px;
    color: #9ca6a9;
  }

  .react-dropdown-select-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-left: 0.9rem;
    font-size: 14px;
    line-height: 19px;
    transition: all 0.3s ease;

    font-weight: 500;
    gap: 4px;
  }

  .css-wmw4vi-ReactDropdownSelect:focus-within {
    // box-shadow: 0 0 5pt 1.5pt #d3d3d3 !important;
    border: 1px solid #01b5f5 !important;
    box-shadow: none !important;
    background-color: #fff;
  }

  .react-dropdown-select-dropdown {
    padding: 4px 0px;
    background: #ffffff;
    border: 1px solid #e3e7eb;
    box-shadow: 0px 2px 5px #e3e7f0;
    border-radius: 2px;
  }

  .items {
    overflow: auto;
    min-height: 10px;
    border: none !important;

    cursor: pointer;

    .vx-checkbox--check {
      background-color: #647074 !important;
    }
    .vx-checkbox-con input:checked ~ .vx-checkbox {
      border-color: #647074;
    }

    .input-holder {
      position: relative;
    }

    .form-control {
      width: 96%;
      margin: 8px;
      padding-left: 38px;
    }
  }

  .item {
    display: flex;
    padding: 12px 16px;

    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    svg {
      path {
        stroke: #32383a;
      }
    }
  }

  .item-selected {
    color: #00b5f4 !important;
    background-color: #f1fbff;
    svg {
      path {
        stroke: #00b5f4 !important;
      }
    }
  }

  .item-label {
    margin: 5px 10px;
  }

  input::placeholder {

    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #32383a !important;
  }

  input:-ms-input-placeholder {

    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #32383a !important;
  }

  input::-ms-input-placeholder {

    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #32383a !important;
  }
}
