// ================================================================================================
// 	File Name: authentication.scss
// 	Description: Page content different authentication pages layouts SCSS.
// 	----------------------------------------------------------------------------------------------
// 	Item Name: Vuexy React Admin Template
// 	Version: 1.0
// 	Author: PIXINVENT
// 	Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// Core mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";
@import "../bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../core/variables/components-variables";

// authentication pages background
.bg-authentication {
  // background: $red url("../../img/pages/map_bg.png");
  background-size: contain;
  opacity: 0.9;
  .login-title {
    margin-top: 1rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
  }
  .auth-title,
  .vx-checkbox-con span,
  .divider .divider-text {
    color: $fts-mid-gray;
  }
  .auth-footer {
    padding: 0rem 1.5rem 0.5rem;
    .footer-btn {
      .btn {
        padding: 0.87rem 1.2rem !important;
        margin: 1rem 1rem 1rem 0rem;
      }
    }
  }
}
.login-card {
  width: 500px;
}

.login-tabs-container h4 {
  font-size: 34px;
  line-height: 40px;
}

.FTS-services-wrapper > div {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;

  .FTS-services-label {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 42px;
    line-height: 40px;
    color: #fff;
    margin: 60px 0px;

    .FTS-services-label-text {
      display: flex;
      flex-direction: column;
    }

    .FTS-services-label-text span:last-of-type {
      padding-left: 20px;
    }
  }

  .FTS-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 10px;
    max-width: 600px;
  }

  .FTS-single-service {
    min-width: 190px;
    color: #fff;
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    span {
      font-size: 19px;
      line-height: 24px;
      margin-left: 12px;
    }
  }
}

// Unlock btn spacing in sm

@media (max-width: 400px) {
  .unlock-btn {
    margin-top: 1rem;
  }
}
