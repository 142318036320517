.fts-updates-list {
  .ag-grid-table {
    height: calc(100vh - 24rem);
  }
}

.fts-updates-header {
  display: flex;
  align-items: center;
  padding-left: 24px;
  font-size: 20px;
  line-height: 24px;
  color: #121a22;
}

.updates-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #e3e7eb;
  margin-left: 1rem;

  svg {
    path {
      stroke: #7d8c91 !important;
    }
  }

  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #7d8c91;
  }
}

.updates-search-button:hover {
  cursor: pointer;
}

.updates-search-active {
  background-color: #f1fbff;
  border: 1px solid #01b5f5;

  .no-select {
    color: #01b5f5;
  }

  svg {
    path {
      stroke: #01b5f5 !important;
    }
  }
}
