/*** Flatpickr ***/

// Core variables and mixins
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/mixins";

// Core variables and mixins overrides
@import "../../../core/variables/variables";
@import "~bootstrap/scss/_variables.scss";

// Overrides user variable
@import "../../../core/variables/components-variables";

.form-control.flatpickr-input {
  background-color: #fbfcfd;
  & ~ input {
    background-color: #fbfcfd;
  }
}

.flatpickr-calendar {
  &.arrowTop {
    &:before,
    &:after {
      border-bottom-color: transparent;
    }
  }
  .flatpickr-months {
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #fbfcfd;

    .flatpickr-prev-month {
      top: 5px;
      left: 10px;
    }

    .flatpickr-next-month {
      top: 5px;
      right: 10px;
    }

    .flatpickr-month {
      background: #fbfcfd;

      select:hover {
        background: #fbfcfd;
      }

      .flatpickr-monthDropdown-months {
        // appearance: none;
        background-color: #fbfcfd;
        font-size: 14px;
        max-width: 80px;
        text-align-last: center;
        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          text-indent: 1px;
          text-overflow: "";
        }
      }
    }
    .numInputWrapper {
      .numInput {
        color: black;
        font-size: 14px;
      }
    }
  }
  .flatpickr-weekdays {
    width: calc(100% + 1px);
    border-bottom: 1px solid #d6ddea;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 100;

    padding-bottom: 10px;
  }

  .flatpickr-weekdays {
    padding: 0px 14px;
    padding-bottom: 12px !important;
  }
  .flatpickr-weekdays,
  .flatpickr-weekday {
    background: #fbfcfd;
    color: #8c919f;
    font-size: 12px;
    line-height: 16px;
  }
  .flatpickr-days {
    .dayContainer {
      padding: 0px 10px;
      .flatpickr-day {
        &.selected,
        &.selected:hover {
          background: $primary;
          border-color: $primary;
        }
        &:hover,
        &.inRange {
          background: $gray-200;
          border-color: $gray-200;
        }

        &.inRange {
          box-shadow: -5px 0 0 $gray-200, 5px 0 0 $gray-200;
        }

        &.flatpickr-disabled {
          color: $secondary;
        }
      }
    }
  }

  .flatpickr-current-month {
    color: #242d34;
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      line-height: 16px !important;
      // text-overflow: "";
    }
  }
}
