@import "../../core/variables/variables";

$uppy-dnd-border-color: #ccd8e4; // dnd = drag n drop

.uppy-DragDrop-inner {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  margin-bottom: -0.2em;
}

.uppy-DragDrop--isDragDropSupported {
  border: 1px dashed $uppy-dnd-border-color;
  border-radius: 2px;
  margin-bottom: 1rem;
}

.uppy-DragDrop-arrow {
  display: none;
}

.uppy-DragDrop-browse {
  color: $primary;
}

.uppy-StatusBar-progress {
  background-color: $primary;
}

.uppy-StatusBar-spinner {
  fill: $primary;
}
