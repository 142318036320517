.file-item {
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  min-height: 4rem;
  width: 100%;
  border: 1px solid #e5ebed;
  border-radius: 1px;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__image {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffe9e9;
  }

  &__extension {
    color: #ff767c;
  }

  &__data {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 0;
  }

  &__name {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &-container {
      margin-bottom: 0;

      color: #242d34;

      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-x: hidden;
    }
  }

  &__size {
    color: #9ca6a9;

    font-size: 0.85rem;
  }

  &__remove {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    svg {
      padding: 4px;
      box-sizing: content-box;
      cursor: pointer;
    }
  }
}
