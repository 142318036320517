@import "../../core/variables/variables";
.fts-action-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: stretch;
  margin-left: -30px;
  margin-right: -31px;
  margin-top: -40px;

  .card {
    box-shadow: none;
    border-bottom: 1px solid #e3e7eb;
  }

  .fts-action-bar {
    .card-body {
      background-color: #f9fafc;
    }

    .card {
      box-shadow: none;
    }

    .row {
      .col-2,
      .col-4,
      .col-6,
      .col-listing {
        padding: 0;

        &::after {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          content: "";
          width: 1px;
          border-right: 1px solid #e5ebed;
          height: 60%;
          margin-top: 5%;
        }

        &:last-of-type {
          &::after {
            display: none;
          }
        }

        .fts-action-bar-item {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          .fts-action-icon {
            margin-bottom: 0.5rem;

            svg {
              // stroke: $fts-dark-gray;
              // fill: $fts-dark-gray;

              path,
              g {
                // stroke: $fts-dark-gray;
                // fill: $fts-dark-gray;
              }
              transition: all 0.3s ease;
            }
          }

          .fts-action-title {
            color: $fts-dark-gray;

            font-size: 0.9rem;
            letter-spacing: 0.08rem;
            text-transform: uppercase;
          }

          &:hover {
            // text-decoration: underline;
            cursor: pointer;

            .fts-action-icon {
              svg {
                transform: scale(1.2);
                transition: all 0.3s ease;
              }
            }
          }
        }
      }
    }
  }

  .fts-action-bar-btn-wrapper {
    display: flex;
    align-items: center;
    margin-top: -2rem;
    width: 184px;

    .account-box {
      flex: 1;
    }
  }
}

@media (max-width: 575.98px) {
  .fts-action-bar-wrapper {
    .fts-action-bar {
      margin-left: 0;

      &.account {
        .row {
          .col-2,
          .col-4,
          .col-6 {
            &:nth-child(3) {
              .fts-action-bar-item {
                border-right-width: 1;
              }
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(6) {
              .fts-action-bar-item {
                border-right-width: 0;
              }
            }
          }
        }
      }

      &.listing {
        .row {
          .col-listing {
            flex-basis: 50%;
            max-width: 50%;
          }
        }
      }
    }

    .fts-action-bar-btn-wrapper {
      display: none;
    }
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .fts-action-bar-wrapper {
    .fts-action-bar {
      margin-left: 0;

      &.account {
        .row {
          .col-2,
          .col-4,
          .col-6 {
            .fts-action-bar-item {
              border-right-width: 1;
            }

            &:nth-child(3) {
              .fts-action-bar-item {
                border-right-width: 0;
              }
            }
          }
        }
      }

      &.listing {
        .row {
          .col-listing {
            flex-basis: 33.333%;
            max-width: 33.333%;
          }
        }
      }
    }
    .fts-action-bar-btn-wrapper {
      display: none;
    }
  }
}

@media (max-width: 1199.98px) {
  .fts-action-bar-wrapper {
    .fts-action-bar {
      .row {
        .col-2 {
          .fts-action-bar-item {
            .fts-action-title {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}
