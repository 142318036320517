@import "./core/variables/variables";

.info-labels {
  display: flex;
  align-items: center;
  padding-bottom: 25px;

  &__box {
    border-radius: 4px;
    background-color: #edf2f3;
    font-size: 12px;
    color: $fts-dark-gray;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    max-height: 33px;

    &:not(:last-of-type) {
      margin-right: 16px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    &__label {
      font-size: 10px;
      color: #7d8c91;

      &--radio {
        color: $primary;
      }
    }

    &__value {
      margin-left: 5px;
    }

    &__value__hot {
      background-color: #ffddde !important;
      color: #f74c54;
      font-size: 10px;
      line-height: 12px;
      padding: 6px 8px 6px;
    }

    &__value__warm {
      background-color: #ffefca;
      color: #f1ae10;
      font-size: 10px;
      line-height: 12px;
      padding: 6px 8px 6px;
    }

    &--radio {
      background-color: transparent;
      border: 1px solid $primary;
    }

    &.active {
      background-color: $primary;
      .info-labels__box__label {
        color: #fff;
      }
    }
  }
}
