.info-message {
  display: flex;
  flex-direction: row;
  background-color: #f2f3f4;
  align-items: center;
  padding: 10px;
  margin-bottom: 22px;
  gap: 15.5px;
  border-radius: 4px;

  &-icon {
    display: inline-block;
  }

  &-text {

    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #32383a;
  }
}
