// Modals

.modal {
  // Modal Header
  .modal-header {
    background-color: $body-bg;
    border-radius: 0.42rem;
    padding: 0.8rem;
    border-bottom: none;

    // close button
    .close {
      padding: 0.2rem 0.62rem;
      box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
      border-radius: 0.357rem;
      background: $white;
      opacity: 1;
      transition: all 0.23s ease 0.1s;
      position: relative;
      transform: translate(8px, -2px);

      span {
        font-weight: 400;
        font-size: 2rem;
        color: $primary;
      }

      // For hover effect of close btn
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        outline: none;
        transform: translate(5px, 3px);
        box-shadow: none;
      }
    }

    &[class*="bg-"] {
      color: $white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;

      .modal-title {
        color: $white;
      }
    }

    // Themed Modals
    &.bg-primary {
      .close {
        span {
          color: $primary;
        }
      }
    }

    &.bg-success {
      .close {
        span {
          color: $success;
        }
      }
    }

    &.bg-info {
      .close {
        span {
          color: $info;
        }
      }
    }

    &.bg-danger {
      .close {
        span {
          color: $danger;
        }
      }
    }

    &.bg-warning {
      .close {
        span {
          color: $warning;
        }
      }
    }

    &.bg-dark {
      .close {
        span {
          color: $dark;
        }
      }
    }
  }

  // Modal Content
  .modal-content {
    border: none;
    // overflow: unset; updated to "visible" as close icon is not visible in IE
    overflow: auto;
    box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
  }

  &.modal-centered {
    .modal-dialog {
      display: flex;
      align-items: center;
    }
  }

  // Modal Footer
  .modal-footer {
    border-color: rgba($pure-black, 0.05);
  }
}

// Modal SM
.modal-sm {
  max-width: 400px;
}

// Modal XS
.modal-xs {
  max-width: 300px;
}

// Modal XL
.modal-xl {
  max-width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}

// To remove Max-width of XS Modal in Small Screen

@media (max-width: 576px) {
  .modal {
    padding-right: 1rem;
    padding-left: 1rem;

    .modal-xs,
    .modal-sm {
      max-width: unset;
    }
  }
}

// For Demo Modal Focus After Close

.demo-modal-form {
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba($success, 0.5) !important;
  }
}

//////////////////////////////////////////////
//                FTS MODAL                 //
//////////////////////////////////////////////

$fts-modal-border-radius: 0.6rem;
$fts-modal-padding: 1.5rem;

.fts-modal {
  // Modal Header
  .modal-header {
    padding: 0;
    background-color: white;
    border-radius: $fts-modal-border-radius;
    border-bottom: none;

    // close button
    .close {
      display: none;
      padding: 0.2rem 0.62rem;
      box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
      border-radius: $fts-modal-border-radius;
      background: $white;
      opacity: 1;
      transition: all 0.23s ease 0.1s;
      position: relative;
      transform: translate(8px, -2px);

      span {
        font-weight: 400;
        font-size: 2rem;
        color: $primary;
      }

      // For hover effect of close btn
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        outline: none;
        transform: translate(5px, 3px);
        box-shadow: none;
      }
    }

    .fts-title-row {
      margin-left: -$fts-modal-padding;

      .col:first-of-type {
        padding-left: $fts-modal-padding - 0.5rem;
      }
    }

    .modal-title {
      width: 100%;
    }

    &[class*="bg-"] {
      color: $white;
      border-bottom-left-radius: $fts-modal-border-radius;
      border-bottom-right-radius: $fts-modal-border-radius;

      .modal-title {
        color: $white;
        width: 100% !important;
      }
    }

    // Themed Modals
    &.bg-primary {
      .close {
        span {
          color: $primary;
        }
      }
    }

    &.bg-success {
      .close {
        span {
          color: $success;
        }
      }
    }

    &.bg-info {
      .close {
        span {
          color: $info;
        }
      }
    }

    &.bg-danger {
      .close {
        span {
          color: $danger;
        }
      }
    }

    &.bg-warning {
      .close {
        span {
          color: $warning;
        }
      }
    }

    &.bg-dark {
      .close {
        span {
          color: $dark;
        }
      }
    }
  }

  // Modal Content
  .modal-content {
    padding: $fts-modal-padding;
    border: none;
    // overflow: unset; updated to "visible" as close icon is not visible in IE
    overflow: auto;
    box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
    border-radius: $fts-modal-border-radius !important;
  }

  &.modal-centered {
    .modal-dialog {
      display: flex;
      align-items: center;
    }
  }

  .modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Modal Footer
  .modal-footer {
    margin: 0 (-$fts-modal-padding) (-$fts-modal-padding) (-$fts-modal-padding) !important;
    padding: 1.5rem $fts-modal-padding !important;
    border: none;
    border-color: rgba($pure-black, 0.05);
  }
}

// Modal SM
.modal-sm {
  max-width: 400px;
}

// Modal XS
.modal-xs {
  max-width: 300px;
}

// Modal XL
.modal-xl {
  max-width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}

// To remove Max-width of XS Modal in Small Screen

@media (max-width: 576px) {
  .modal {
    padding-right: 1rem;
    padding-left: 1rem;

    .modal-xs,
    .modal-sm {
      max-width: unset;
    }
  }
}

// For Demo Modal Focus After Close

.demo-modal-form {
  .btn-outline-success:focus {
    box-shadow: 0 0 0 0.2rem rgba($success, 0.5) !important;
  }
}

.fts-modal-new {
  // Modal Header
  .modal-content {
    background: $modal-background;
  }

  .modal-header {
    padding: 0;
    background-color: white;
    border-radius: $fts-modal-border-radius;
    border-bottom: none;

    // close button
    .close {
      display: none;
      padding: 0.2rem 0.62rem;
      box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
      border-radius: $fts-modal-border-radius;
      background: $white;
      opacity: 1;
      transition: all 0.23s ease 0.1s;
      position: relative;
      transform: translate(8px, -2px);

      span {
        font-weight: 400;
        font-size: 2rem;
        color: $primary;
      }

      // For hover effect of close btn
      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        outline: none;
        transform: translate(5px, 3px);
        box-shadow: none;
      }
    }

    .modal-title {
      width: 100%;
      background-color: $modal-background;
    }

    // Themed Modals
    &.bg-primary {
      .close {
        span {
          color: $primary;
        }
      }
    }

    &.bg-success {
      .close {
        span {
          color: $success;
        }
      }
    }

    &.bg-info {
      .close {
        span {
          color: $info;
        }
      }
    }

    &.bg-danger {
      .close {
        span {
          color: $danger;
        }
      }
    }

    &.bg-warning {
      .close {
        span {
          color: $warning;
        }
      }
    }

    &.bg-dark {
      .close {
        span {
          color: $dark;
        }
      }
    }
  }

  // Modal Content
  .modal-content {
    padding: $fts-modal-padding;
    border: none;
    // overflow: unset; updated to "visible" as close icon is not visible in IE
    overflow: auto;
    box-shadow: 0 5px 20px 0 rgba($pure-black, 0.1);
    border-radius: $fts-modal-border-radius !important;
  }

  &.modal-centered {
    .modal-dialog {
      display: flex;
      align-items: center;
    }
  }

  .modal-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Modal Footer
  .modal-footer {
    margin: 0 (-$fts-modal-padding) (-$fts-modal-padding) (-$fts-modal-padding) !important;
    padding: 1.5rem $fts-modal-padding !important;
    border: none;
    border-color: rgba($pure-black, 0.05);
  }
}

.duplicated-listing-info {
  background-color: #f5b053;
  padding: 10px 10px 10px 50px;
  display: flex;
  align-items: center;
  color: #fff;
}

.deleted-listing-info {
  background-color: #f20933;
  padding: 10px 10px 10px 50px;
  display: flex;
  align-items: center;
  color: #fff;
}

.locked-listing-info {
  background-color: #00B5F4;
  padding: 10px 10px 10px 50px;
  display: flex;
  align-items: center;
  color: #fff;

  svg {
    path {fill: #fff}
  }
}

.changed-listing-type {
  background-color: #ffe0e6;
  padding: 10px 50px 10px 50px;
  display: flex;
  align-items: center;
  color: #191c1d;

  path {
    stroke: #242d34 !important;
  }

  .cancel-upgrade-button {
   color: #CE1B3B !important;
   min-height: 48px !important;
   height: auto !important;
  }

  .cancel-upgrade-button:focus {
    color: #CE1B3B !important;
   }

   .cancel-upgrade-button:active {
    color: #CE1B3B !important;
   }
}
