#rich_text_editor .ql-container {
  font-family: "Kumbh Sans", sans-serif !important;
  letter-spacing: 0;
}
#rich_text_editor .ql-container.ql-snow {
  height: calc(100% - 40px);
  border: 1px solid #f3f6f9;
  border-radius: 0 0 4px 4px;
}
#rich_text_editor .ql-editor {
  padding: 16px;
  scrollbar-width: none;
}
#rich_text_editor .ql-editor::-webkit-scrollbar {
  display: none;
}
#rich_text_editor .ql-editor strong {
  font-weight: 600;
}
#rich_text_editor .ql-editor * {
  letter-spacing: 0;
  font-family: "Kumbh Sans", sans-serif !important;
  color: #2a343e !important;
}
#rich_text_editor .text-2xs, #rich_text_editor .text-2xs * {
  font-size: 16px !important;
  line-height: 20px !important;
}
#rich_text_editor .text-3xs, #rich_text_editor .text-3xs * {
  font-size: 14px !important;
  line-height: 20px !important;
}
#rich_text_editor .ql-editor ul,
#rich_text_editor .ql-editor ol,
#rich_text_editor .ql-editor ul li,
#rich_text_editor .ql-editor ol li {
  padding: 0;
}
#rich_text_editor .ql-editor li::before {
  margin: 0;
  width: 24px;
  text-align: center;
}
#rich_text_editor .ql-toolbar.ql-snow {
  background: #f3f6f9;
  border: unset !important;
}
#rich_text_editor .ql-editor.ql-blank::before {
  content: none;
}
#rich_text_editor .ql-editor.ql-blank::after {
  content: attr(data-placeholder);
  margin-top: -20px;
  display: block;
}

.without-toolbar .ql-container.ql-snow {
  padding: 16px;
  height: 100% !important;
}
.without-toolbar .ql-editor {
  max-height: 240px;
  overflow: hidden;
  padding: 0 !important;
}
.without-toolbar .ql-toolbar {
  display: none;
}
.without-toolbar .quill.description {
  color: #2a343e;
}
.without-toolbar .ql-container.ql-snow {
  background: #f3f6f9;
}

#rich_text_editor.text-2xs .ql-container, #rich_text_editor.text-2xs .ql-editor * {
  font-size: 16px !important;
  line-height: 20px !important;
}

#rich_text_editor.text-3xs .ql-container, #rich_text_editor.text-3xs .ql-editor * {
  font-size: 14px !important;
  line-height: 20px !important;
}

#rich_text_editor.reverse {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  border-radius: 4px 4px 0 0;
  gap: 20px;
}
#rich_text_editor.reverse .ql-container.ql-snow {
  overflow: scroll;
  border-radius: 4px;
}
#rich_text_editor.reverse .ql-container.ql-snow:focus-within {
  border-color: #54a4de;
}
#rich_text_editor.reverse .ql-toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -20px;
}
