.count-box-wrapper {
  display: flex;
  margin-bottom: 5px;

  .icon-wrapper {
    height: 100%;
    display: flex;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      border-radius: 50%;
    }
  }

  .fts-icon {
    stroke: none;
  }

  .info-small {
    display: flex;
    flex-direction: column;
    margin-left: 12px;

    .count {

      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #32383a;
    }
    .label {

      font-size: 14px;
      line-height: 16px;
      color: #647074;
    }
  }

  .percentage {
    margin-top: 11px !important;
    margin-left: 12px !important;
  }

  .info-medium {
    display: flex;
    align-items: flex-end;
    margin-left: 12px;

    .count {

      font-weight: bold;
      font-size: 29px;
      line-height: 32px;
      color: #32383a;
    }
    .label {

      font-size: 14px;
      line-height: 16px;
      color: #32383a;
      margin-left: 6px;
      margin-bottom: 2px;
    }
  }
}

.bordered-count-box {
  height: 40px;
  padding-left: 8px;
  display: flex;
  justify-content: space-between;

  .label {

    font-size: 12px;
    line-height: 14px;
    color: #32383a;
  }

  .count {

    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #32383a;

    .empty {
      color: #b8b6ae;
    }
  }
}

#percentage-tooltip {

  font-size: 12px;
  line-height: 14px;
  color: #fff !important;
  padding: 4px 8px !important;
  background-color: #4b5457;
  border-radius: 4px;

  margin-bottom: 3px !important;
}

.tooltip {
  .arrow {
    margin-bottom: 3px !important;
  }
  .arrow::before {
    border-top-color: #4b5457 !important;
  }

  .arrow::after {
    display: none;
  }
}
