.skeleton-wrapper {
  hr {
    color: #e4bcc4 !important;
    margin-top: 11px;
    margin-bottom: 18px;
  }

  .skeleton {
    background-color: #fff;
    box-shadow: 0px 2px 5px #e3e7f0;
    padding: 0;
    transition: all 0.3s ease;
    padding: 24px;
    margin-left: 1px;
    width: 297px;
    margin-bottom: 1rem;

    .header {
      margin-bottom: 14px;
    }
  }

  .icon {
    margin-right: 10px;
  }

  .owner {
    margin-right: 16px;
  }

  .bottom {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
