@import "../core/variables/variables";

.account-no-permission {
  position: absolute;
  margin: -40px -31px;
  min-width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: center;
  //align-items: center;
  > h1 {
    margin-top: 30px;
    color: white;
  }
}

.account-section {
  // border: 1px solid $gray-200;
  margin-top: 1rem;
  box-sizing: border-box;

  &.no-border {
    border: none;
    padding: 0;
    margin-top: 1.5rem;
  }

  &.first {
    margin-top: 0;

    .fts-reverse-switch-group {
      margin-top: -0.4rem;

      .fts-reverse-switch {
        margin-bottom: 0.25rem;

        &#corporate-switch {
          margin-bottom: 0;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
          transform: translateX(1.45rem);
        }
      }
    }

    .switch-label {
      padding-right: 0.5rem;
    }
  }
}

.business-section-wrapper {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -25px;
    left: -2.5rem;
    right: -2.5rem;
    bottom: 0;
    background-color: #f8f8f8;
    border-bottom: 1px solid #e5ebed;
  }
}

.fts-account-nav-wrapper {
  overflow: auto;
  max-width: 100%;
}

.fts-nav-container {
  float: left;
}

.fts-account-tab-wrapper {
  min-height: 60vh;
  // padding: 2.5rem !important;
  padding: 24px 0 !important;
  padding-top: 19px;

  hr {
    margin: 2rem 0;
  }

  .row {
    .column {
      .btn {
        margin-left: -2rem;
      }
    }

    .account-info-col-2-fifths {
      flex-basis: 40%;
      max-width: 40%;
      // padding-bottom: 0.85rem;
      // display: flex;
      // flex-direction: column-reverse;
    }
  }

  .account-info-first-row {
    .account-info-col-fifth {
      flex-basis: 20%;
      max-width: 20%;
      margin-bottom: 0.5rem;
    }
  }
}

.fts-account-tab-files-title-wrapper {
  margin-bottom: 0.5rem;

  .fts-account-tab-files-title {
    padding: 1.75rem 2.5rem;

    .fts-title-row {
      margin-bottom: 0;
    }
  }
}

.tab-pane {
  padding-left: unset !important;
  background-color: transparent !important;
  overflow-y: unset !important;

  .card {
    border: 1px solid #e3e7eb;
    border-radius: 4px;
    box-shadow: none;
  }
}

.account-settings-tab {
  box-shadow: none !important;
  width: 184px;
  padding: 0 !important;

  .nav-item {
    margin-bottom: 0.8rem;

    .nav-link {
      display: flex;
      align-items: center;
      border-radius: $border-radius !important;

      font-size: 1.05rem !important;
      transition: background-color 0.3s ease;

      &.active {
        // background-color: white !important;
        // box-shadow: 0 2px 16px rgba(0, 0, 0, 0.14);
        transform: translateY(0) !important;

        transition: background-color 0.5s ease;

        .fts-icon {
          path {
            stroke: #01b5f5;
            stroke: none;
            fill: #01b5f5;
            stroke-width: 1;
          }
        }

        &:after {
          display: none;
          left: auto !important;
          top: 1.2rem !important;
          right: -1.5rem !important;
        }
      }
    }
  }
}

.account-setting-wrapper {
  .account-settings-tab {
    .nav-item {
      .nav-link {
        &.active {
          &:after {
            top: 100% !important;
            left: 0 !important;
          }
        }
      }
    }
  }
}

#account-info-section {
  .fts-note-header-new,
  .fts-note-header__label > span {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .fts-account-tab-wrapper {
    .account-info-first-row {
      .account-info-col-fifth {
        flex-basis: 33.333%;
        max-width: 33.333%;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .fts-reverse-switch-group {
    margin-top: 0;
    margin-bottom: 1rem;
  }
}

@media (max-width: 767.98px) {
  .fts-account-tab-wrapper {
    .account-info-first-row {
      .account-info-col-fifth {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .account-settings-tab {
    .nav-item {
      .nav-link.active {
        &:after {
          display: none;
        }
      }
    }
  }

  .fts-account-tab-wrapper {
    padding: 1.5rem;

    .account-info-first-row {
      .account-info-col-fifth {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .fts-account-remove-btn {
      width: 100%;
      margin-bottom: 1rem;
    }

    .fts-account-save-btn {
      width: 100%;
    }
  }
}
