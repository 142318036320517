.report-dropdown-wrapper {
    padding: 0px;
    width: 100%;


    font-size: 14px;
    line-height: 16px;

    .report-item-with-menu {
        position: relative;

        .dropdown {
            background-color: white;
            position: absolute;
            width: 280px;
            left: -285px;
            top: 3px;
            border: 1px solid #E3E7EB;
            border-radius: 4px;
            padding: 24px;
            box-shadow: 0px 2px 5px 0px rgba(227, 231, 240, 1);


            .nav {
                display: flex;
                align-items: center;
                justify-content: space-around;
                background-color: #f1f5fa;
                height: 48px;
                border-radius: 4px;
                margin-bottom: 24px;
            }

            .tab {
                height: 40px;
                border-radius: 4px;
                margin: 0;
                padding: 0;
                flex: 1;
                font-weight: normal !important;

                font-size: 14px;
                line-height: 16px;
                margin-left: 4px;
                margin-right: 4px;
                outline: none;
            }

            .tab:focus {
                outline: 0 !important;
            }

            .selected {
                border: none;
                background-color: #ffffff;
                color: #242d34;
                box-shadow: 0px 2px 5px #cdd8e6;
            }

            .unselected {
                background-color: transparent;
                border: none;
                color: #7d8c91;
            }

            .vx-checkbox--check {
                background-color: #647074 !important;
              }
            .vx-checkbox-con input:checked ~ .vx-checkbox {
                border-color: #647074;
            }
        }
    }
}
