.fts-access-granted-badge {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid #57c990;
  border-radius: 24px;
  height: 24px;
  padding: 4px 8px;
  padding-right: 0px;

  font-weight: 500;
  font-size: 12px;
  line-height: 14px;

  svg {
    path {
      stroke: #57c990;
    }
  }

  .lock {
    margin-right: 6px;
  }

  .chevron {
    margin-left: 3px;
  }

  .text {
    color: #57c990;
  }
}
