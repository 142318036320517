.FTS-chart-subheading {
  position: relative;
  padding-bottom: 20px;
  padding-top: 10px;
}

.FTS-chart-subheading-flex {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-left: 6px solid #f30833;
  height: 24px;
  margin-left: -2.5rem;
  padding-left: 2rem;
  letter-spacing: 0.8px;
}

.FTS-chart-subheading h2 {
  font-size: 20px;
  line-height: 24px;
  color: #32383a;

  font-weight: 700;
  margin: 0;
}

.FTS-chart-subheading p {
  font-size: 14px;
  line-height: 18px;

  color: #4b5457;
  margin-top: 13px;
}

@media (max-width: 600px) {
  .FTS-chart-subheading {
    padding-top: 40px;
  }

  .FTS-chart-heading-underline {
    top: 90px;
  }
}
