.alt-contacts {
  padding-bottom: 2rem;

  &__item {
    border: 1px solid #e5ebed;
    background-color: #fff;

    &__header {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 1rem;
    }

    &__title {
      padding-right: 1.5rem;
    }

    &__icon {
      margin-left: auto;
    }

    &__content {
      margin-top: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .note-type-wrapper {
        display: none;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 0.8rem;
    }
  }
}
