.account-report {
  padding: 2.5rem !important;

  .invalid-formik-input {
    border-color: #f20933 !important;
  }

  .fts-title-row .col h4 {
    font-size: 16px;
    line-height: 24px;

    font-style: none !important;
    text-decoration: none !important;
    color: #2c2c2c;
    font-weight: normal;
    font-style: unset;
    font-weight: bold !important;
    letter-spacing: 1.9px !important;
  }

  .header-report-controls {
    display: flex;
    padding: 0px;
    margin-top: 1rem;
    margin-right: 1rem;
    min-height: 48px;

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #f1f5fa;
      height: 48px;
      margin-right: 1rem;
      border-radius: 4px;
      margin-right: 1rem;
    }

    .tab {
      height: 40px;
      min-width: 46px;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      font-weight: normal !important;

      font-size: 14px;
      line-height: 16px;
      outline: none;
    }

    .tab:focus {
      outline: 0 !important;
    }

    .tab:first-child {
      margin-left: 4px;
      margin-right: 2px;
    }

    .tab:last-child {
      margin-left: 2px;
      margin-right: 4px;
    }

    .selected {
      border: none;
      background-color: #ffffff;
      color: #242d34;
      box-shadow: 0px 2px 5px #cdd8e6;
    }

    .unselected {
      background-color: transparent;
      border: none;
      color: #7d8c91;
    }

    .range {
      width: 270px;
      height: 48px;

      .formik-validation-error {
        margin-left: 0.5rem;
      }

      .clear-calendar-button {
        transition: 3000ms color ease;

        svg {
          stroke: #ccc;
        }
      }

      .clear-calendar-button:hover {
        svg {
          stroke: #999;
        }
      }

      .form-control-position {
        z-index: 3;
      }

      .fts-date-picker {
        //  background-color: transparent !important;
        border: 1px solid #e3e7eb;
        border-radius: 4px;
        margin-left: 0.5rem;
      }
    }

    .flatpickr-input {
      background-color: transparent !important;
    }
  }

  .generate-report-button {
    svg {
      margin-right: 0.5rem !important;
    }
  }

  .FTS-listing-info-report {
    margin-left: -2.5rem;

    .FTS-chart-listing-header {
      padding-left: 2.5rem;
    }

    .FTS-chart-listing-info {
      margin-left: 38px;
      padding-top: 10px;
      border-bottom: 1px solid #e5e8e9;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 20px;
      line-height: 24px;
      color: #32383a;

      font-weight: 700;
      border-left: 6px solid #f30833;
    }
  }

  .nav-item:first-child {
    padding-left: 1.6rem;
  }

  .account-report-content {
    margin-left: -2.5rem !important;
    margin-right: -2.5rem !important;

    .tab-content {
      padding: 0px 2.5rem;
      padding-top: 1rem;
    }

    .ag-pinned-right-cols-container * {
      border-left: none !important;
    }

    .ag-pinned-right-header {
      border: none !important;
    }

    .ag-pinned-right-header {
      border: none !important;
    }

    .ag-row {
      border-radius: 0px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    .ag-cell-first-right-pinned {
      border-left: 1px solid #e5ebed !important;
      box-shadow:
        -2px 0px 5px #e5e8e9,
        0px 1px 0px #e5e8e9;
    }
  }
}
