.fts-primary-devider {

    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.8px;
    width: 100%;
    display: flex;
    align-items: center;
    color: #00B5F4;
    text-transform: uppercase;
    white-space: nowrap;

    .fts-primary-devider-line {
        width: 100%;
        hr {
            margin-left: 12px;
            border-top: 1px solid #edf2f3 !important;
        }
    }
}
