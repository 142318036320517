.fts-reassign-modal {
  .update-listing-confrimation-modal-cta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 2rem;
    width: 100%;

    .back-button {
      display: flex;
      align-items: center;

      svg {
        margin-right: 6px;
        path {
          stroke: #626262 !important;
        }
      }
    }
  }

  .reassign-modal-body {
    .update-listing-confirmation-title {
      h3 {

      }
      span {

      }

      .confirmation-spinner-wrapper {
        margin-left: 1rem;
        margin-top: 2px;
      }
    }
  }
}
