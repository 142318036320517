.upgrades-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 6.5px;
  padding-bottom: 6.5px;
  transition: padding-left 0.35s ease 0s;
  padding-left: 10px;
  width: 230px;
  border-radius: 4px;
  width: 99%;
  height: 43px;

  .upgrades-text {
    display: flex;
    align-items: center;

    color: #f3f3f3;
    font-size: 15px;
    line-height: 22px;
    margin-left: -5px;
  }

  .menu-icons {
    display: flex;
  }

  .text-content {
    display: flex;
    align-items: center;
    background-color: black;
  }

  .upgrades-number {
    background: rgba(167, 24, 49, 1);
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    border-radius: 0.8em;
    padding: 2px 4px;
    min-width: 24px;
    border-radius: 50px;
  }

  .closed-upgrades-indicator {
    background-color: #fff;
    color: #f36565;
    // padding: 1px 5px;
    border-radius: 40px;
  }
}

.upgrades-container:hover {
  cursor: pointer;
  padding-left: 1rem;
}

.notify {
  height: 7px;
  width: 7px;
  position: absolute;
  background: #00b5f4;
  top: -1px;
  right: 14px;
  border-radius: 10px;
}

.upgrades-container:hover {
  padding-left: 22px;
}

.active-upgrades-tab {
  .upgrades-container {
    background: linear-gradient(118deg, #a71831, rgba(167, 24, 49, 0.7));
    .upgrades-number {
      background-color: transparent;
    }
  }
}
