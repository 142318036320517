@import "../../core/variables/variables";

.fts-todo {
  &__owner {
    > div {
      &:last-of-type {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
      }
    }

    h4 {
      margin: 0;
      font-size: 11px;
      color: #242d34;
      font-weight: normal;

      + div {
        font-size: 9px;
        color: #7d8c91;
        line-height: 1;
      }
    }
  }

  &__participants {
    display: flex;
    flex-direction: row-reverse;

    .avatar {
      margin: 0;
      border: 1px solid #fff;
      transition: all 0.3s ease;

      &:not(:first-of-type) {
        margin-right: -12px;
      }
    }

    &:hover {
      .avatar {
        margin-right: 0;
      }
    }
  }
}

.fts-todo-empty {
  color: #7d8c91;
  height: auto;
  min-width: 125px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  background-color: transparent;
  border: 2px dashed #c8dae0;
  margin: 0 1rem 8px 1rem;
  transition: all 0.5s ease;
  border-radius: 3px;
  text-align: center;
  padding: 1rem;

  font-weight: normal !important;

  .drag-icon {
    margin-bottom: 0.5rem;
  }

  .link {
    color: #01b5f5;

    font-size: 14px;
    margin: 0;
    padding: 0;
  }
  .link:hover {
    cursor: pointer;
  }
  .text {
    margin: 0;
    padding: 0;
  }
}

.fts-todo-placeholder {
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
  color: #47596f;
  font-size: 16px;
  line-height: 23px;

  .link {
    color: #01b5f5;
    font-weight: bold;

    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  .link:hover {
    cursor: pointer;
  }
}

.fts-todo-empty-search {

  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  text-align: center;
  color: #47596f;
  font-size: 16px;
  line-height: 23px;

  .empty-text {
    font-size: 24px;
    line-height: 23px;
    font-weight: normal;
  }

  .empty-small-text {
    font-size: 16px;
    line-height: 23px;
    font-weight: normal;
  }
}

.no-border {
  border: none !important;
}

.fts-todo-small {
  display: flex;
  align-items: center;
  align-content: center;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  padding: 0;
  transition: all 0.3s ease;
  border-radius: 3px;
  margin-bottom: 1rem;

  .fts-todo-status-label {
    // position: absolute;
    background-color: $success;
    // left: 1rem;
    width: 4px;
    height: 100%;
    // padding-left: 0.5rem;
    writing-mode: vertical-rl;
    text-align: left;
    text-orientation: upright;
    text-transform: uppercase;
    transition: all 0.3s ease;

    div {
      width: 0;
      // margin-left: -1rem;

      // &.visible {
      //   width: 1rem;
      //   margin-left: 0;
      //   transition: all 0.3s ease;
      // }
    }
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .fts-todo-small-body {
    flex: 1;
    padding: 15px;

    &.active {
      border-color: $success;
    }

    &.inactive {
      border-color: $gray-300;
    }

    &.expired {
      border-color: $danger;
    }

    .fts-todo-title {
      font-size: 13px;
      color: #242d34;

    }

    .fts-todo-bottom-row {
      display: flex;
      align-items: center;
      margin-top: 10px;
      justify-content: space-between;

      .fts-todo-date {
        font-size: 10px;
        color: #242d34;
        text-transform: uppercase;

        margin-bottom: -2px;

        small {
          vertical-align: middle;
          margin-left: 0.2rem;
        }
      }

      .fts-todo-attached {
        span {
          padding-left: 0.25rem;
          font-size: 10px;
          color: #7d8c91;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 10px 12px -2px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    // transform: translateY(-5px);

    .fts-todo-status-label {
      padding-left: 1.5rem;
      transition: all 0.3s ease;
    }
  }

  .fts-todo-small-header {
    margin-bottom: 0.5rem;
  }
}
