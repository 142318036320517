
.credit-card-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 24px;
  border: 1px solid #E5E8E9;
  margin-top: 1.5rem;

  .credit-card-fields {
    flex: 1;

    .labels {
      color: #242d34;
      font-size: 13px;
      line-height: 15px;

      font-weight: normal !important;
    }

    .form-group {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }

    .form-control {
      border-radius: 3px !important;
      border: 1px solid #ccd8e4;
      height: 40px;

      font-size: 15px !important;
      line-height: 18px !important;
      letter-spacing: 0.428571px !important;
      margin-top: 0.5rem;
      margin-bottom: 1rem;

      &::placeholder {
        color: #b5bfc6 !important;
      }
    }
  }

  .credit-card-fields:not(:first-child) {
    margin-left: 25px;
  }
}

.credit-card-wrapper {
  .fts-icon {
    stroke: none;
  }
}
