.fts-square-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 10px;
  line-height: 13px;
  padding: 6px 8px;
  height: 24px;
}
