.percentage-box-wrapper {

  border-radius: 24px !important;
  max-width: max-content;
  padding: 3px 6px 3px 4px;
  font-size: 12px;
  line-height: 14px;
  height: 20px !important;
}

.percentage-red {
  background-color: #ffeaea;
  border: 1px solid #ffeaea;
  color: #f36565;
}

.percentage-red:hover {
  cursor: pointer;
  border: 1px solid #f36565;
}

.percentage-green {
  background-color: #eafce4;
  border: 1px solid #eafce4;
  color: #6aaa52;
}

.percentage-green:hover {
  cursor: pointer;
  border: 1px solid #6aaa52;
}
