
.report-dropdown-item {
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: #7d8c91;
  font-weight: normal;
  padding: 12px 0.3rem 12px 0.7rem;
  border-radius: 2px !important;

  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: normal;
  }


  .report-dropdown-icons {
    .fts-icon {
      height: 17px;
      width: 17px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .report-dropdown-disabled-icons {
    .fts-icon {
      height: 17px;
      width: 17px;
      path {
        stroke: #adb5c2 !important;
      }
      margin-right: 10px;
    }
  }
}

.report-dropdown-red {
  color: #CE1B3B !important;
  .fts-icon {
    margin-right: 10px;
    margin-left: 10px;
    height: 17px;
    width: 17px;
  }
}
