.activity-wrapper {
  width: 96%;
  padding: 24px;
  border: 1px solid #e5ebed;
  background: #fff;
  border-radius: 4px;
  margin-bottom: 1rem;

  transition: all 0.1s ease;

  .fts-badge {
    font-weight: bold !important;
    font-size: 12px !important;
  }

  .activity-icon {
    margin-top: 0.75rem;
  }

  .new-listing-icon {
    margin-right: 8px;
    border: 1px solid #e5e8e9;
    border-radius: 50%;
    margin-top: 8px;
    svg {
      margin: 5px;
    }
  }

  svg {
    margin-right: 8px;
    fill: none;
  }

  .reject-icon {
    svg {
        stroke: #9ca6a9;
    }
  }

  .fts-badge-inline {
    background-color: #9ca6a9 !important;
    padding: 0.5rem !important;
    border-radius: 23px !important;
    height: 20px !important;
    color: #fff;
    //
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    padding: 2px 8px !important;
  }

  .contacted-person {
    color: #242d34;
    text-decoration: underline;
  }

  .activity-data-title {
    color: #9ca6a9;
    margin-bottom: 4px;
  }

  .voided-invoice {
    text-decoration: underline;
  }

  .activity-date-time {
    color: #9ca6a9;
  }

  .new-listing-activity {
    .activity-date-time {
      margin-left: 44px;
    }
  }

  .account-link {
    text-decoration: underline !important;
  }

  .account-link:hover {
    cursor: pointer;
    color: #01b5f5;
  }
}

.activity-wrapper:hover {
  border: 1px solid #9ca6a9;
}
