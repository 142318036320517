@import "../../core/variables/variables";

.backButton-container {
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  }
}

.create-header {
  padding: 0 2.5rem;
  height: 72px;
  border-bottom: 1px solid #e5ebed;

  &__title {
    font-size: 21px;
    color: $fts-dark-gray;
  }
}
