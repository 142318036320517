.fts-activity {
  display: flex;
  padding: 15px;
  border: 1px solid #ededed;
  border-radius: 3px;
  margin-top: 25px;

  &__left {
    display: flex;
    align-items: center;

    h4 {
      margin: 0;
      font-size: 14px;

      + div {
        font-size: 13px;

        span {
          font-size: 11px;
          display: inline-block;
          padding: 3px 7px;
          border: 1px solid #222222;
          border-radius: 3px;
          font-weight: bold;
        }
      }
    }
  }

  &__img {
    margin-right: 15px;

    img {
      border-radius: 30px;
    }
  }

  &__right {
    margin-left: auto;
  }

  &__date {
    font-size: 12px;
  }
}
