.renewals-wrapper {
  .fts-renewals-header {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    color: #121a22;
    padding-left: 24px;
  }
  .header-renewals-buttons {
    display: flex;
    align-items: center;

    .renewals-search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 48px;
      border-radius: 4px;
      background-color: transparent;
      border: 1px solid #e3e7eb;
      margin-left: 1rem;
      margin-right: 1.5rem;

      .fts-icon {
        g {
          fill: #191c1d !important;
        }
      }
    }

    .renewals-search-button:hover {
      cursor: pointer;
    }

    .renewals-search-active {
      background-color: #f1fbff;
      border: 1px solid #01b5f5;

      .fts-icon {
        g {
          fill: #01b5f5 !important;
        }
      }
    }

    .admin-picker {
      .select__control {
        background-color: transparent !important;
        border-radius: 4px !important;
        border: 1px solid #e3e7eb !important;
      }
    }

    .range {
      width: 270px;
      height: 48px;

      .clear-calendar-button {
        transition: 3000ms color ease;
        svg {
          stroke: #ccc;
        }
      }

      .clear-calendar-button:hover {
        svg {
          stroke: #999;
        }
      }

      .form-control-position {
        z-index: 3;
      }

      .fts-date-picker {
        background-color: transparent !important;
        border: 1px solid #e3e7eb !important;
        border-radius: 4px;
        margin-left: 1rem;
      }
    }

    .flatpickr-input {
      background-color: transparent !important;
    }

    .nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background-color: #f1f5fa;
      height: 48px;
      border-radius: 4px;
      padding-right: 1px;
      width: 197px;
      margin-left: 1rem;
    }

    .tab {
      height: 40px;
      width: 60px;
      border-radius: 4px;
      margin: 0;
      padding: 0;
      font-weight: normal !important;

      font-size: 14px;
      line-height: 16px;
      outline: none;
    }

    .tab:focus {
      outline: 0 !important;
    }

    .selected {
      border: none;
      background-color: #ffffff;
      color: #242d34;
      box-shadow: 0px 2px 5px #cdd8e6;
    }

    .unselected {
      background-color: transparent;
      border: none;
      color: #7d8c91;
    }
  }

  .search-box {
    margin-top: 28px;
    .row {
      margin-left: 0;
      margin-right: -0.5rem;
    }

    .search-input-group {
      border-radius: 4px;
    }

    input:-webkit-autofill:focus,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #fbfcfd inset;
    }

    .search-input-group {
      transition: unset;
      transition: border-color 0.3s ease 0s;
    }

    .states-picker {
      .select__control {
        border: 1px solid #e3e7eb !important;
        border-radius: 4px !important;
        height: 56px !important;
      }
    }

    .clear-button {
      display: flex;
      align-items: center;
      border-radius: 4px !important;
      border: 1px solid #e3e7eb;
      padding: 12px 24px;
      height: 48px;
      background-color: #fff !important;
      color: #242d34;
      margin-right: 1rem;
    }

    .clear-button:hover {
      box-shadow: 0 8px 25px -8px #babfc7 !important;
    }

    .search-button {
      margin-right: 0.5rem;
    }
  }

  // .row-renewals-red {
  //   background-color: #fff5f7;
  // }
}

.renewals-flex-wrapper {
  .card {
    padding-top: 15px;
    margin-bottom: 2.2rem;
    border-radius: 4px;
    border: 1px solid #e3e7eb;
    box-shadow: none;
  }

  .ag-grid-table {
    height: calc(100vh - 31rem);
    margin-top: 1rem;

    .ag-header-cell-label .ag-header-cell-text {
      font-size: 13px;
      color: #7d8c91;
      font-weight: normal;
    }
  }

  .app-user-list {
    .renewals-tables {
      padding: 0 14px;

      .manual-renewals-checkbox {
        display: flex;
        align-items: center;
        margin-left: auto;

        font-size: 14px;
        list-style: 16px;
        padding-right: 24px;
        color: #32383a;
        margin-bottom: 3px;

        .checkbox-label {
          margin-left: 0.5rem;
        }

        .no-select {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        }

        .checkbox-label:hover {
          cursor: pointer;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
