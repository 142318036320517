@import "../../core/variables/variables";

.fts-note-small-left-col {
  max-width: 4rem;
}

.fts-note-small-right-col {
  max-width: 100%;
}

.fts-new-reply-wrapper {
  display: flex;
  flex-direction: column;

  .fts-new-reply {
    margin-top: 1rem;
  }
}

.fts-note-small {
  background-color: #fcf7e8;
  border: 1px solid #e3e8e9;
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  .fts-badge {
    font-weight: bold !important;
  }

  .fts-note-small-header-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .fts-note-small-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 19.5px;
  }

  .small-note-label {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 16px;
    //margin-bottom: 18px;
    font-size: 12px !important;
    padding: 4px 4px;
    border-radius: 26px !important;
  }

  .fts-note-small-header-row {
    display: flex;
    flex-direction: row;
    width: 100%;

    :first-child {
      margin-bottom: 0;
    }
  }

  .fts-linked-text {
    color: $primary;
    font-size: 14px;
  }

  .unpin-btn {
    cursor: pointer;
    font-size: 14px;
    margin-left: 26px;

    span {
      margin-left: 5px;
    }
  }

  .fts-note-linked {
    display: flex;
    // align-items: center !important;
    cursor: pointer;

    .fts-label {
      font-size: 13px;
      padding: 6px 4px;
    }
  }

  .toggle-text {
    display: inline-block;
    appearance: none;
    border: none;
    padding: 0;
    background-color: transparent;
  }

  .fts-label {
    margin-right: 0.5rem;
  }

  &:hover {
    box-shadow: 0 10px 12px -2px rgba(0, 0, 0, 0.08);
    transition: all 0.3s ease;
    transform: translateY(-5px);
  }

  .remove-note-btn {
    color: #f36565;
    margin-right: 4px;

    span {
      margin-left: 0.5rem;
    }
  }

  .remove-note-btn:hover {
    cursor: pointer;
  }

  &.nested {
    border: unset;
    padding: unset;

    &:hover {
      box-shadow: unset;
      transform: unset;
    }

    &.long {
      border: 1px solid #e3e8e9;
      padding: 24px;
      border-radius: 4px;
    }
  }

  &.nested:last-child {
    margin-bottom: 0 !important;

    .fts-note-small-created-by {
      margin-bottom: 0 !important;
    }
  }

  h4 {
    font-size: 11px;
    color: #242d34;
    vertical-align: bottom;
    margin: 0;
  }

  p {
    color: #242d34;


    &:last-of-type {
      margin-bottom: 0;
    }
  }

  small {
    vertical-align: top;
    font-size: 10px;
    color: #7d8c91;
  }

  &.activity {
    border: none;
    padding: 0;
    margin-bottom: 0;
  }

  .small-note-user-info {
    display: flex;
    // justify-content: space-between;
  }

  .fts-note-small-created-by {
    > span {
      margin-bottom: 1rem;
    }

    display: flex;
    flex-direction: column;
    // align-items: center;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0.5rem;

    .avatar {
      width: 24px;
      height: 24px;
      align-items: center;
      margin-right: 12px;
    }

    .owner {

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #242d34;
    }

    &.created-by-long {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &.note-created-by {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  .fts-note-reply-created-by {
    align-items: flex-start;

    &.long {
      border: 1px solid #e3e8e9;
      padding: 24px;
      border-radius: 4px;
    }
  }

  .fts-note-small-time {
    display: flex;
    align-items: center;
    padding-left: 2px;
    font-size: 13px;
    line-height: 16px;
    color: #7d8c91;

    svg {
      margin-right: 0.5rem;
      stroke-width: 1;
      width: 15px;
      flex: 0 0 15px;
    }
  }

  .note-info {
    display: flex;
    flex-direction: column;

    .avatar {
      height: 100%;
    }

    > div {
      margin-top: 1rem;
    }
  }

  .reply-info {
    display: flex;
    flex-direction: column;

    .avatar {
      margin-right: 12px;
    }

    .reply-text {
      padding-left: 2.6rem;
    }

    > div {
      margin-top: 1rem;
    }
  }

  .fts-note-reply-btn {
    color: black;
    padding: 1rem 0.5rem;

    svg {
      transition: stroke 0.3s ease;
    }

    span {

      margin-left: 0.5rem;
      color: $primary;
      transition: color 0.3s ease;
    }

    &:focus {
      color: white;

      svg {
        stroke: white;
        transition: stroke 0.3s ease;
      }

      span {
        color: white;
        margin-left: 0.5rem;
        transition: color 0.3s ease;
      }
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.opened {
  transform: rotate(180deg) !important;
  transition: all 0.25s ease;
}

.closed {
  transition: all 0.25s ease;
}

.fts-replies-btn {
  cursor: pointer;
  margin-left: 15px;
}

.fts-note-replies-wrapper {
  .fts-note-small {
    background-color: transparent;
  }
}
