.description-wrapper {
  .description-box {
    display: flex;
    width: 100%;

    .form-group label.disabled {
      font-size: 14px;
      line-height: 18.59px;
      letter-spacing: 0.04rem;
      color: #4b5457 !important;
    }

    .description-label {
      font-size: 14px;
      line-height: 18.59px;
      letter-spacing: 0.04rem;
      color: #4b5457 !important;
      margin-bottom: 16px;
    }

    .quill {
      width: 537px !important;
    }

    .ql-editor {
      height: 274px !important;
      overflow: hidden !important;
      width: 537px;

      font-size: 14px !important;
      line-height: 21px !important;
    }

    .ql-toolbar {
      border: 1px solid #e3e7eb;
      border-bottom: none;
      border-top-left-radius: 2px !important;
      border-top-right-radius: 2px !important;
    }

    .ql-container {
      height: 274px !important;
      background-color: #fbfcfd !important;
      border: 1px solid #e3e7eb;
      border-bottom-left-radius: 2px !important;
      border-bottom-right-radius: 2px !important;
    }

    .ql-editor.ql-blank::before {
      color: #7d8c91;
    }

    // .description-premium {
    //   height: 237px !important;
    //   min-height: 237px !important;
    //   max-height: 237px !important;
    // }

    // .description-standard {
    //   height: 120px !important;
    //   min-height: 120px !important;
    //   max-height: 120px !important;
    // }

    // .description-basic-plus {
    //   height: 50px !important;
    //   min-height: 50px !important;
    //   max-height: 50px !important;
    // }

    // .description-basic {
    //   height: 0px !important;
    //   min-height: 0px !important;
    //   max-height: 0px !important;
    // }

    // .description-free {
    //   height: 0px !important;
    //   min-height: 0px !important;
    //   max-height: 0px !important;
    // }

    .description {
      width: 507px !important;
      margin-left: 0px !important;
      margin-right: 32px !important;

      font-size: 14px !important;
      line-height: 21px !important;
      overflow: hidden !important;

      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: right;
      }

      p {
        font-size: 14px !important;
        line-height: 21px !important;
        //   margin: 0 !important;
      }

      ul {
        font-size: 14px !important;
        //   margin-bottom: 0 !important;
      }

      li {
        line-height: 17px !important;

        font-size: 14px !important;
        line-height: 21px !important;
        //   margin: 0 !important;
      }

      code {
        font-size: 14px !important;
        line-height: 21px !important;
        background-color: transparent !important;
      }
    }

    .description * {
      font-size: 14px !important;
      line-height: 21px !important;

      color: #2c2f36;
      overflow-x: visible;
      background-color: transparent !important;
    }
  }
}
