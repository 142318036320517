.search-header {

  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  padding: 8px 1rem 12px 1rem;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #242d34;
  width: 100%;
}

.colapse-button {

  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #01b5f5;
}

.collapse-row {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 10px;
  margin: 0;
  margin-top: 8px;

  hr {
    margin-top: 31px;
    margin-bottom: 24px;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

.search-title {
  display: flex;
  height: 24px;
  align-items: center;
  margin-bottom: 0;
  margin-left: -2.5rem;
  padding: 0 !important;
  padding-left: 26px !important;
  border-left: 6px solid #ff4f57;

  font-style: normal;
  font-weight: bold !important;
  font-size: 16px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.chevron {
  margin-right: 8px;
}

.search-checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.left-search-column {
  width: 60%;
  padding-right: 11px;
}

.right-search-column {
  width: 40%;
  padding-left: 13px;
}

.search-button {

  border-radius: 4px !important;
  padding: 12px 24px;
  height: 48px;
  margin-left: 1rem;
}

.clear-button {
  display: flex;
  align-items: center;
  border-radius: 4px !important;
  border: 1px solid #e3e7eb;
  padding: 12px 24px;
  height: 48px;
  background-color: #fff !important;
  color: #242d34;
  margin-left: 1rem;
}

.clear-button:hover {
  box-shadow: 0 8px 25px -8px #babfc7 !important;
}
.total-search-button {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  height: 48px;
  background-color: #fff !important;
  color: #242d34;
  margin-left: 1rem;
}

.clear-button:hover {
  box-shadow: 0 8px 25px -8px #babfc7 !important;
}

.switch-text {

}
